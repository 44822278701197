import { SearchOption } from 'components/SearchBar/SearchBar'
import { FaqCategory, Loadstate, LoggedUser, TipsTricks, User } from 'graphql/types'
import { GeneralState } from './GeneralState.reducer'

export enum GeneralStateAction {
    DEFAULT = 'DEFAULT', // Only for testing purposes
    SET_LOADSTATE = 'SET_LOADSTATE',
    SET_LOGGED_USER = 'SET_LOGGED_USER',
    SET_USER = 'SET_USER',
    SET_FAQ_LIST = 'SET_FAQ_LIST',
    SET_TIPS_LIST = 'SET_TIPS_LIST',
    SET_SEARCH_TERMS = 'SET_SEARCH_TERMS',
    SET_GENERAL_STATE_PARTIAL = 'SET_GENERAL_STATE_PARTIAL',
}

type GeneralStateActions =
    | { type: GeneralStateAction.DEFAULT }
    | { payload: Loadstate; type: GeneralStateAction.SET_LOADSTATE }
    | { payload: LoggedUser; type: GeneralStateAction.SET_LOGGED_USER }
    | { type: GeneralStateAction.SET_USER; payload: User }
    | { type: GeneralStateAction.SET_FAQ_LIST; payload: FaqCategory[] }
    | { type: GeneralStateAction.SET_TIPS_LIST; payload: TipsTricks[] }
    | { type: GeneralStateAction.SET_SEARCH_TERMS; payload: SearchOption[] }
    | { type: GeneralStateAction.SET_GENERAL_STATE_PARTIAL; payload: Partial<GeneralState> }

export default GeneralStateActions
