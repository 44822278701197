import { Box, makeStyles, Typography, useTheme } from '@material-ui/core'
import * as React from 'react'
import colors from '../../utils/colors'
import TestIDs from '../../utils/TestIDs'

export interface FieldWrapperProps {
    children?: JSX.Element | null
    text: string
}

const useStyles = makeStyles(() => ({
    childrenWrapper: {
        '& .MuiFilledInput-root': {
            backgroundColor: colors.white,
            '&.Mui-disabled': {
                backgroundColor: colors.lightGray,
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: colors.white,
            },
        },
    },
}))

const FieldWrapper = (props: FieldWrapperProps): JSX.Element | null => {
    const { children, text } = props
    const theme = useTheme()
    const classes = useStyles()

    if ((children === null || children === undefined) && text === '') return null
    return (
        <Box
            {...TestIDs.GET_AUTH_PAGES('FIELD_WRAPPER')}
            bgcolor={theme.palette.primary.light}
            width={1}
            flex={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            padding={{ xs: 2, sm: 5 }}
        >
            {children !== null && children !== undefined && (
                <Box
                    className={classes.childrenWrapper}
                    width={1}
                    flex={1}
                    display={'flex'}
                    flexDirection={'column'}
                    padding={{ xs: 1, md: 3 }}
                >
                    {children}
                </Box>
            )}
            {text !== '' && (
                <Box
                    width={1}
                    flex={1}
                    textAlign={'left'}
                    display={'flex'}
                    paddingBottom={3}
                    paddingTop={children !== null && children !== undefined ? 3 : 0}
                >
                    <Typography {...TestIDs.GET_AUTH_PAGES('TEXT_FIELD')}>{text}</Typography>
                </Box>
            )}
        </Box>
    )
}

export default FieldWrapper
