import { Box, Container, Grid } from '@material-ui/core'
import Footer from 'components/Footer'
import Header, { HeaderProps } from 'components/Header/Header'
import LoadingSpinner from 'components/LoadingSpinner'
import NavigationBar, { NavigationBarProps } from 'components/NavigationBar/NavigationBar'
import UserBar from 'components/UserBar/UserBar'
import * as React from 'react'
import { useEffect } from 'react'
import { useViewWrapperData } from './useViewWrapperData'

export interface ViewWrapperProps {
    header: HeaderProps
    navigation: NavigationBarProps
    children?: JSX.Element
}

const ViewWrapper = (props: ViewWrapperProps): JSX.Element => {
    const { children, header, navigation } = props
    const { loadstate, loggedUser, onLogoutClick } = useViewWrapperData()

    useEffect(() => {
        try {
            // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
            // 0 doesn't work on all occasions so -1000 makes sure its at the top when navigating
            window.scroll({
                top: -1000,
                left: -1000,
                behavior: 'smooth',
            })
        } catch (error) {
            console.log('error:', error)

            // just a fallback for older browsers
            window.scrollTo(-1000, -1000)
        }
    }, [window.location])

    return (
        <>
            <UserBar user={loggedUser.username} onLogoutClick={onLogoutClick} />
            <Header {...header} />
            <NavigationBar {...navigation} />
            {children && (
                <Container maxWidth={'md'}>
                    <Box marginTop={{ xs: 5, sm: 10 }} width={1}>
                        {children}
                    </Box>
                    <Grid item xs={12}>
                        <LoadingSpinner loading={loadstate.loading} />
                    </Grid>
                </Container>
            )}
            <Footer />
        </>
    )
}

export default ViewWrapper
