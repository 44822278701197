import { gql } from '@apollo/client'

export const UpdateCustomerAddress = gql`
    mutation updateCustomerAddress($input: AddressInput!, $kind: String!) {
        updateCustomerAddress(input: $input, kind: $kind) {
            Success
            AddressKind
        }
    }
`
