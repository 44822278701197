import { useMutation } from '@apollo/client'
import { SearchOption } from 'components/SearchBar/SearchBar'
import { UPDATE_LOGIN_PW } from 'graphql/mutations/UpdateLoginPW'
import { Mutation, User } from 'graphql/types'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppState } from 'store/store'

interface UseMyDataStateReturn {
    user: User
    searchTerms: SearchOption[]
    handlePasswordChange: () => void
}

const useMyDataState = (): UseMyDataStateReturn => {
    const { user, searchTerms } = useSelector((appState: AppState) => ({
        user: appState.generalState.user,
        searchTerms: appState.generalState.searchTerms,
    }))
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const [updateLoginPW] = useMutation<Mutation>(UPDATE_LOGIN_PW, {
        onCompleted: (data) => {
            if (data.updateLoginPW === true) {
                enqueueSnackbar(t('notification.sendPwRequest'), {
                    variant: 'success',
                })
            }
            if (data.updateLoginPW === false) {
                enqueueSnackbar(t('notification.sendPwRequestFail'), {
                    variant: 'error',
                })
            }
        },
    })

    const handlePasswordChange = () => {
        updateLoginPW()
    }

    return {
        // TODO: if this page is loaded we already know the userdata is there rework the redux state
        user: user ? user : ({} as User),
        searchTerms,
        handlePasswordChange,
    }
}

export default useMyDataState
