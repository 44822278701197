import { useMutation, useQuery } from '@apollo/client'
import DateFnsUtils from '@date-io/date-fns'
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Dropdown from 'components/Dropdown'
import ErrorBox from 'components/ErrorBox'
import FieldWrapper from 'components/FieldWrapper'
import LoadingSpinner from 'components/LoadingSpinner'
import ViewWrapper from 'components/ViewWrapper'
import { format } from 'date-fns'
import { SEND_CONTRACT_TERMINATION } from 'graphql/mutations/SendContractTermination'
import { GET_CONTRACTS_TERMINATION } from 'graphql/queries/getContractsTermination'
import { ContractTermination, Mutation, MutationSendContractTerminationArgs, Query, Termination } from 'graphql/types'
import { getTerminationText } from 'pages/CancelContractConfirmation/CancelContractConfirmation/CancelContractConfirmation'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { AppState } from 'store/store'
import { DropdownOption, getHeader, navigationLinks } from 'utils/utils'
import deLocale from 'date-fns/locale/de'

type CancelContractParams = {
    contractID?: string
}

const CancelContract = (): JSX.Element => {
    const params = useParams<CancelContractParams>()
    const [errors, setErrors] = useState<string[]>([])
    const [terminationType, setTerminnationType] = useState('earliestDate')
    const [selectedContract, setSelectedContract] = useState(params.contractID ? params.contractID : '')
    const [reason, setReason] = useState('')
    const [userReason, setUserReason] = useState('')
    const [contractTermination, setContractTermination] = useState<ContractTermination | null>(null)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTerminnationType((event.target as HTMLInputElement).value)
    }

    const { loading: loadingData } = useQuery<Query>(GET_CONTRACTS_TERMINATION, {
        onCompleted: (data) => {
            if (data.getContractsTermination) {
                setContractTermination(data.getContractsTermination)
            }
        },
    })
    const terminationToContractDropdown = (c: Termination[]): DropdownOption[] => {
        const opts: DropdownOption[] = []
        for (let i = 0; i < c.length; i++) {
            const label =
                c[i].contractID +
                ' / ' +
                c[i].articleDescription +
                ' / ' +
                format(new Date(c[i].targetEnd), 'dd.MM.yyyy')
            opts.push({ label: label, value: c[i].contractID })
        }
        return opts
    }

    // hooks
    const { t } = useTranslation()
    const history = useHistory()
    const options = useMemo(() => {
        return terminationToContractDropdown(contractTermination ? contractTermination.terminations : [])
    }, [contractTermination])
    const { user, customize } = useSelector((appState: AppState) => ({
        user: appState.generalState.user,
        customize: appState.generalState.customize,
    }))
    const selectedOption = useMemo(() => {
        return options.find((o) => o.value === selectedContract)?.value
    }, [options, selectedContract])

    const reasonstoDropDown = (vs: string[]): DropdownOption[] => {
        const opts: DropdownOption[] = []
        for (let i = 0; i < vs.length; i++) {
            if (vs[i] === '') {
                continue
            }
            opts.push({ label: vs[i], value: vs[i] })
        }
        return opts
    }
    const reasons: DropdownOption[] = useMemo(() => {
        if (contractTermination) {
            switch (terminationType) {
                case 'earliestDate':
                    setReason('')
                    return reasonstoDropDown(contractTermination.terminationReasons.terminationForConvenience)
                case 'customDate':
                    setReason('')
                    return reasonstoDropDown(contractTermination.terminationReasons.terminationForCause)
                default:
                    setReason('')
                    return []
            }
        }
        setReason('')
        return []
    }, [terminationType, contractTermination, setReason])

    const [selectedDate, setSelectedDate] = useState<Date | null>(null)

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date)
    }

    const handleSave = (): void => {
        if (user) {
            const err: string[] = []
            let kind = 'terminationForConvenience'
            if (selectedContract === '') {
                err.push('selectedContractEmpty')
            }
            if (reason === '') {
                err.push('reasonEmpty')
            }
            if (reason === 'Sonstiges' && userReason === '') {
                err.push('userReasonEmpty')
            }
            if (terminationType === 'customDate') {
                kind = 'terminationForCause'
                if (selectedDate === null) {
                    err.push('customDateEmpty')
                }
            }
            if (err.length > 0) {
                setErrors(err)
                return
            } else if (err.length === 0) {
                setErrors(err)
            }

            const contractItem = contractTermination?.terminations.find((o) => o.contractID === selectedContract)
            let targetEnd = contractItem ? contractItem.targetEnd : ''
            let targetReason = reason
            if (reason === 'Sonstiges' && userReason !== '') {
                targetReason = userReason
            }
            if (selectedDate) {
                targetEnd = format(selectedDate, 'yyyy-MM-dd')
            }
            if (contractItem && customize) {
                const name = user.salutation + ' ' + user.firstName + ' ' + user.lastName
                const sendDate = new Date()
                const txt = getTerminationText(
                    kind,
                    t,
                    name,
                    sendDate.getTime().toString(),
                    targetEnd,
                    contractItem.contractID,
                    contractItem.articleDescription,
                    reason,
                    customize.cancellation.ordinary,
                    customize.cancellation.extraOrdinary,
                )
                const vars: MutationSendContractTerminationArgs = {
                    input: {
                        contractID: selectedContract,
                        kind: kind,
                        targetEnd: targetEnd ? targetEnd : '',
                        reason: targetReason,
                        email: user.email,
                        usernameFull: name,
                        articleDescription: contractItem.articleDescription,
                        sendDate: sendDate.getTime(),
                        txt: txt,
                    },
                }
                sendTermination({ variables: vars })
            }
        }
    }
    const [sendTermination, { loading }] = useMutation<Mutation>(SEND_CONTRACT_TERMINATION, {
        onCompleted: (data) => {
            if (data.sendContractTermination) {
                const contractItem = contractTermination?.terminations.find((o) => o.contractID === selectedContract)
                if (contractItem) {
                    history.push(
                        `/cancelContractConfirmation/${encodeURIComponent(
                            contractItem.contractID,
                        )}/${encodeURIComponent(contractItem.articleDescription)}/${new Date().getTime()}/${
                            selectedDate ? format(selectedDate, 'yyyy-MM-dd') : contractItem.targetEnd
                        }/${
                            terminationType === 'earliestDate' ? 'terminationForConvenience' : 'terminationForCause'
                        }/${encodeURIComponent(reason)}`,
                    )
                }
            }
        },
    })

    useEffect(() => {
        if (selectedContract !== '' && contractTermination && terminationType === 'earliestDate') {
            const contractItem = contractTermination?.terminations.find((o) => o.contractID === selectedContract)
            if (contractItem) {
                setSelectedDate(new Date(contractItem.targetEnd))
            }
        }
        if (selectedContract !== '' && contractTermination && terminationType === 'customDate') {
            setSelectedDate(null)
        }
    }, [selectedContract, contractTermination, terminationType])

    const minDate = useMemo(() => {
        if (selectedContract !== '' && contractTermination && terminationType === 'earliestDate') {
            const contractItem = contractTermination?.terminations.find((o) => o.contractID === selectedContract)
            if (contractItem) {
                return new Date(contractItem.targetEnd)
            }
        }
        if (selectedContract !== '' && contractTermination && terminationType === 'customDate') {
            setSelectedDate(null)
            return new Date()
        }
    }, [selectedContract, contractTermination, terminationType])

    if (!user || Object.keys(user ?? {}).length === 0) {
        history.replace('/')
        return <></>
    }

    return (
        <ViewWrapper
            header={getHeader(`/${t('url.dashboard')}`, history, t, [])}
            navigation={navigationLinks('logged-in', 'contracts', t)}
        >
            <>
                {loadingData && <LoadingSpinner loading={loadingData} />}
                {contractTermination && (
                    <>
                        <Typography variant={'h1'} style={{ marginBottom: 72 }}>
                            {t('cancelContract')}
                        </Typography>
                        <FieldWrapper text={''}>
                            <Grid container justify="center" alignItems="center" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">{t('pages.cancelContract.info1')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">{t('pages.cancelContract.info2')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">{t('pages.cancelContract.info3')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth={true}
                                        label={t('pages.cancelContract.customerID')}
                                        variant={'filled'}
                                        disabled
                                        value={user?.customerID}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <TextField
                                        fullWidth={true}
                                        label={t('pages.cancelContract.contractOwner')}
                                        variant={'filled'}
                                        disabled
                                        value={user?.firstName + ' ' + user?.lastName}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">{t('pages.cancelContract.cancelation')}</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Dropdown
                                        id={'contractCancelOpts'}
                                        disabled={false}
                                        focused={undefined}
                                        label={t('pages.cancelContract.contract')}
                                        options={options}
                                        selectedOption={selectedOption ? selectedOption : ''}
                                        handleChange={(e) => {
                                            const val = e.target.value as string
                                            setSelectedContract(val)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <RadioGroup value={terminationType} onChange={handleChange}>
                                            <FormControlLabel
                                                style={{ marginBottom: 20 }}
                                                value="earliestDate"
                                                control={<Radio color="default" />}
                                                label={t('pages.cancelContract.earliestDate')}
                                            />
                                            <FormControlLabel
                                                value="customDate"
                                                control={<Radio color="default" />}
                                                label={t('pages.cancelContract.customDate')}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                                        <DatePicker
                                            disablePast
                                            disabled={selectedContract === ''}
                                            minDate={minDate}
                                            variant="dialog"
                                            format="dd.MM.yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label={t('pages.cancelContract.customDateInput')}
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            okLabel={<Typography>{t('pages.cancelContract.ok')}</Typography>}
                                            cancelLabel={<Typography>{t('pages.cancelContract.cancel')}</Typography>}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <Dropdown
                                        disabled={false}
                                        focused={undefined}
                                        label={t('pages.cancelContract.reason')}
                                        options={reasons}
                                        selectedOption={reason}
                                        handleChange={(e) => {
                                            const val = e.target.value as string
                                            setReason(val)
                                        }}
                                    />
                                </Grid>
                                {reason === 'Sonstiges' && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('pages.cancelContract.reasonInput')}
                                            variant={'filled'}
                                            value={userReason}
                                            inputProps={{
                                                maxLength: 256,
                                            }}
                                            onChange={(e) => {
                                                setUserReason(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </FieldWrapper>
                        <Box>{errors.length > 0 && <ErrorBox errors={errors} />}</Box>
                        <Grid
                            container
                            justify="space-around"
                            alignItems="center"
                            spacing={4}
                            style={{ marginBottom: 20, marginTop: 20 }}
                        >
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant={'outlined'}
                                    color={'default'}
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                >
                                    {t('general.cancel')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant={'contained'}
                                    color={'primary'}
                                    disabled={loading}
                                    onClick={handleSave}
                                >
                                    {t('pages.cancelContract.submit')}
                                    {loading ? '...' : ''}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </>
        </ViewWrapper>
    )
}

export default CancelContract
