import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { GraphQLError } from 'graphql/error/GraphQLError'
import { ErrorConfig } from 'graphql/types'
import { KeycloakInstance } from 'keycloak-js'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const useApolloClient = (
    graphQLServerURI: string,
    setError: (error: ErrorConfig) => void,
    keycloak: KeycloakInstance,
): ApolloClient<unknown> | undefined => {
    const [apolloClient, setApolloClient] = React.useState<ApolloClient<unknown>>()
    const { t } = useTranslation()

    const authLink = new ApolloLink((operation, forward) => {
        const accessToken = keycloak.token
        operation.setContext(({ headers }: Record<string, any>) => {
            return {
                headers: {
                    ...headers,
                    authorization: accessToken ? `Bearer ${accessToken}` : '',
                },
            }
        })
        return forward(operation)
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            let auxErr: string[] = []
            auxErr = graphQLErrors.map(({ message }: GraphQLError): string => {
                return message
            })
            setError({
                title: t('graphQLErrorTitle'),
                content: t('graphQLErrorContent') + ':',
                messages: auxErr,
            })
        } else if (networkError) {
            setError({
                title: t('networkErrorTitle'),
                content: t('networkErrorContent'),
                messages: [networkError.message],
            })
        }
    })
    const httpLink = new HttpLink({ uri: graphQLServerURI })

    const newLink = authLink.concat(httpLink)

    React.useEffect(
        () => {
            setApolloClient(
                new ApolloClient({
                    cache: new InMemoryCache(),
                    link: from([errorLink, newLink]),
                }),
            )
        },
        // eslint-disable-next-line
        [],
    )

    return apolloClient
}
