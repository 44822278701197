import { useLazyQuery } from '@apollo/client'
import { SearchOption } from 'components/SearchBar/SearchBar'
import { GET_CONTRACT_OVERVIEW } from 'graphql/queries/GetContractOverview'
import { ContractMetaData, ContractOverview, Query, QueryGetContractOverviewArgs } from 'graphql/types'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState } from 'store/store'

export interface ContractOverviewState {
    searchTerms: SearchOption[]
    userID: string
    gotoDocs: () => void
    overview: ContractOverview | undefined
    loading: boolean
    contractMetaData: ContractMetaData | undefined
    handleChangeContractView: (index: number) => void
    activeContractIDs: string[]
}

export const useContractOverview = (): ContractOverviewState => {
    const { searchTerms, userID, contractMetaData } = useSelector((appState: AppState) => ({
        searchTerms: appState.generalState.searchTerms,
        userID: appState.generalState.user?.customerID,
        contractMetaData: appState.generalState.user?.contractMetaData,
    }))
    const [data, setData] = useState<ContractOverview | undefined>(undefined)
    const [cachedData, setCachedData] = useState<ContractOverview[]>([])
    const history = useHistory()
    const [fetchOverview, { loading }] = useLazyQuery<Query>(GET_CONTRACT_OVERVIEW, {
        onCompleted: (data) => {
            if (data.getContractOverview) {
                setData(data.getContractOverview)
                const isAlreadyCached = _.findIndex(
                    cachedData,
                    (d) => d.contractID === data.getContractOverview.contractID,
                )
                if (isAlreadyCached === -1) {
                    setCachedData([...cachedData, data.getContractOverview])
                }
            }
        },
    })

    useEffect(() => {
        if (!data) {
            if (contractMetaData && contractMetaData.sendInvoice.length > 0) {
                const vars: QueryGetContractOverviewArgs = {
                    contractID: contractMetaData.activeContractIDs[0],
                }
                fetchOverview({ variables: vars })
            } else {
                history.replace('/')
            }
        }
    }, [contractMetaData, data])

    const handleChangeContractView = (index: number) => {
        if (contractMetaData) {
            const id = contractMetaData.activeContractIDs[index]
            const cached = _.find(cachedData, (d) => d.contractID === id)
            if (cached) {
                setData(cached)
            } else {
                const vars: QueryGetContractOverviewArgs = {
                    contractID: contractMetaData.activeContractIDs[index],
                }
                fetchOverview({ variables: vars })
            }
        }
    }

    const gotoDocs = () => {
        history.push('/contractDocs')
    }

    return {
        searchTerms,
        userID: userID ? userID : '',
        gotoDocs,
        overview: data,
        loading,
        contractMetaData,
        handleChangeContractView,
        activeContractIDs: contractMetaData ? contractMetaData.activeContractIDs : [],
    }
}
