import { useLazyQuery } from '@apollo/client'
import { useKeycloak } from '@react-keycloak/web'
import { format, parse } from 'date-fns'
import GetUserData from 'graphql/queries/GetUserData'
import { Loadstate, LoggedUser, Query, User } from 'graphql/types'
import { KeycloakInstance, KeycloakTokenParsed } from 'keycloak-js'
import { Dispatch, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { AppState } from 'store/store'

export interface ViewWrapperDataReturn {
    loadstate: Loadstate
    loggedUser: LoggedUser
    onLogoutClick: () => void
}

export const useViewWrapperData = (): ViewWrapperDataReturn => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()
    const { loadstate, loggedUser, user } = useSelector((appState: AppState) => ({
        loadstate: appState.generalState.loadstate,
        loggedUser: appState.generalState.loggedUser,
        user: appState.generalState.user,
    }))

    const setUser = useCallback(
        (payload: User) => {
            dispatch({ type: GeneralStateAction.SET_USER, payload })
        },
        [dispatch],
    )

    const { keycloak } = useKeycloak()

    const setLoadstate = useCallback(
        (payload: Loadstate) => {
            dispatch({ type: GeneralStateAction.SET_LOADSTATE, payload })
        },
        [dispatch],
    )

    const setLoggedUser = useCallback(
        (payload: LoggedUser) => {
            dispatch({ type: GeneralStateAction.SET_LOGGED_USER, payload })
        },
        [dispatch],
    )

    const [getUser] = useLazyQuery<Query>(GetUserData, {
        onCompleted: (data) => {
            if (data && data.getUserData) {
                // NOTE: parse the date once for the frontend and parse it back when sending
                let parsedDate = ''
                if (data.getUserData.birthdate && data.getUserData.birthdate.trim().length > 0) {
                    parsedDate = format(parse(data.getUserData.birthdate, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy')
                }
                setUser({ ...data.getUserData, birthdate: parsedDate })
            }
        },
    })

    const onLogoutClick = (): void => {
        keycloak.logout({ redirectUri: window.location.origin })
    }

    const getUserFromKeycloak = async (keycloakInstance: KeycloakInstance) => {
        const tokenParsed: KeycloakTokenParsed | undefined = keycloakInstance.tokenParsed
        if (tokenParsed) {
            const email: string | undefined = tokenParsed?.email
            const id: string | undefined = tokenParsed?.sub
            const roles: string[] | undefined = keycloakInstance.realmAccess?.roles
            const username = tokenParsed?.preferred_username
            setLoggedUser({
                id: id ? id : '',
                email: email ? email : '',
                roles: roles ? roles : [],
                username: username,
            })
        }
    }

    useEffect(() => {
        setLoadstate({ ...loadstate, loading: true })
        getUserFromKeycloak(keycloak)
    }, [])

    useEffect(() => {
        if (loggedUser.id.trim().length > 0 && user === undefined) {
            getUser()
        }
    }, [loggedUser, user])

    useEffect(() => {
        if (user) {
            setLoadstate({ ...loadstate, loading: false })
        }
    }, [user])

    return {
        loadstate,
        loggedUser,
        onLogoutClick,
    }
}
