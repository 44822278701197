import { gql } from '@apollo/client'

export const GET_CONTRACT_METADATA = gql`
    query GetContractMetaData {
        getContractMetaData {
            activeContractIDs
            evn {
                contractID
                value
            }
            sendInvoice {
                contractID
                sendInvoicePostal
                sendInvoiceKupo
            }
            activeContracts {
                contractID
                contractStart
                contractEnd
                possibleContractEnd
                lastDateForCancellation
            }
        }
    }
`
