import { Box, Button, Grid, Typography } from '@material-ui/core'
import { BankData } from 'graphql/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import colors from 'utils/colors'
import TestIDs from 'utils/TestIDs'
import { getFormattedIbanByInput } from 'utils/utils'

export interface MyBankDataProps {
    data: BankData
}

const MyBankData = (props: MyBankDataProps): JSX.Element => {
    const { data } = props
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <Box
            padding={{ xs: 2, sm: 5 }}
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            bgcolor={colors.lightGray}
            height="100%"
        >
            <Box marginBottom={5} display="flex" alignItems="center" justifyContent="flex-start" width="100%">
                <Typography variant={'h2'}>{t('pages.myData.billing.title')}</Typography>
            </Box>

            <Grid item container justify="flex-end"></Grid>
            <Grid container spacing={2}>
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <Typography style={{ color: colors.midDarkerGray }}>
                            {t('pages.myInvoices.billingBankData.accountHolder')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>{data.accountHolder}</Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <Typography style={{ color: colors.midDarkerGray }}>
                            {t('pages.myInvoices.billingBankData.iban')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>{getFormattedIbanByInput(data.iban)}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Box display="flex" flex={1} alignItems="flex-end" justifyContent="flex-end">
                <Button
                    variant={'outlined'}
                    onClick={() => history.push(t('url.BankDataEdit'))}
                    TouchRippleProps={{ style: { color: colors.main } }}
                    {...TestIDs.GET_BANK_DATA('EDIT_BUTTON')}
                >
                    {t('pages.myData.billing.viewDetails')}
                </Button>
            </Box>
        </Box>
    )
}

export default MyBankData
