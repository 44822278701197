import { Box, Hidden, Typography } from '@material-ui/core'
import InvoiceListDesktop from 'components/InvoiceListDesktop'
import InvoiceListMobile from 'components/InvoiceListMobile/InvoiceListMobile'
import ViewWrapper from 'components/ViewWrapper'
import useMyInvoicesState from 'pages/MyInvoices/useMyInvoicesState'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import TestIDs from 'utils/TestIDs'
import { getHeader, navigationLinks } from 'utils/utils'

const MyInvoicesPage = (): JSX.Element => {
    const { t } = useTranslation()
    const history = useHistory()
    const {
        invoices,
        pageSize,
        selectedInvoices,
        searchTerms,
        downloadItems,
        items,
        changeOrder,
        selectOne,
        selectAll,
        docTypeOrder,
        dateOrder,
        page,
        billAmount,
        billIsLoading,
    } = useMyInvoicesState()

    return (
        <ViewWrapper
            header={getHeader(`/${t('url.dashboard')}`, history, t, searchTerms)}
            navigation={navigationLinks('logged-in', 'myInvoices', t)}
        >
            <Box display={'flex'} flexDirection={'column'}>
                <Box width={1} marginBottom={9}>
                    <Box>
                        <Typography variant="h1" {...TestIDs.GET_MY_INVOICES_PAGE('HEADLINE')}>
                            {t('pages.myInvoices.title')}
                        </Typography>
                        <Box marginTop={1}>
                            <Typography variant="body1">{t('pages.myInvoices.subTitle')}</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box marginBottom={7.5} {...TestIDs.GET_MY_INVOICES_PAGE('INVOICE_LIST')}>
                    <Hidden xsDown={true}>
                        <InvoiceListDesktop
                            invoices={invoices}
                            invoicesPerPage={pageSize}
                            downloadItems={downloadItems}
                            selectedInvoices={selectedInvoices}
                            changeOrder={changeOrder}
                            selectOne={selectOne}
                            selectAll={selectAll}
                            items={items}
                            docTypeOrder={docTypeOrder}
                            dateOrder={dateOrder}
                            page={page}
                            billAmount={billAmount}
                            billIsLoading={billIsLoading}
                        />
                    </Hidden>
                    <Hidden smUp={true}>
                        <InvoiceListMobile
                            invoices={invoices}
                            invoicesPerPage={pageSize}
                            downloadItems={downloadItems}
                            selectedInvoices={selectedInvoices}
                            changeOrder={changeOrder}
                            selectOne={selectOne}
                            selectAll={selectAll}
                            items={items}
                            docTypeOrder={docTypeOrder}
                            dateOrder={dateOrder}
                            page={page}
                            billAmount={billAmount}
                            billIsLoading={billIsLoading}
                        />
                    </Hidden>
                </Box>
            </Box>
        </ViewWrapper>
    )
}

export default MyInvoicesPage
