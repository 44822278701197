import { Box, Container, Grid } from '@material-ui/core'
import Footer from 'components/Footer'
import Header, { HeaderProps } from 'components/Header/Header'
import LoadingSpinner from 'components/LoadingSpinner'
import NavigationBar, { NavigationBarProps } from 'components/NavigationBar/NavigationBar'
import * as React from 'react'

export interface ViewWrapperProps {
    header: HeaderProps
    loading: boolean
    children?: JSX.Element
    navigation?: NavigationBarProps
}

const ViewWrapperPublic = (props: ViewWrapperProps): JSX.Element => {
    const { children, navigation, header, loading } = props

    return (
        <>
            <Header {...header} />
            {navigation && <NavigationBar {...navigation} />}
            {children && (
                <Container maxWidth={'md'}>
                    <Box marginTop={{ xs: 5, sm: 10 }} width={1}>
                        {children}
                    </Box>
                    <Grid item xs={12}>
                        <LoadingSpinner loading={loading} />
                    </Grid>
                </Container>
            )}
            <Footer />
        </>
    )
}

export default ViewWrapperPublic
