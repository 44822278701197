import { ApolloProvider } from '@apollo/client'
import DateFnsUtils from '@date-io/date-fns'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useKeycloak } from '@react-keycloak/web'
import ErrorDialog from 'components/ErrorDialog1'
import LoadingSpinner from 'components/LoadingSpinner'
import deLocale from 'date-fns/locale/de'
import { ErrorConfig } from 'graphql/types'
import { SnackbarProvider } from 'notistack'
import BankDataEdit from 'pages/BankDataEdit/BankDataEdit'
import CancelContract from 'pages/CancelContract'
import CancelContractConfirmation from 'pages/CancelContractConfirmation/CancelContractConfirmation/CancelContractConfirmation'
import ContractOverview from 'pages/ContractOverview'
import ContractPage from 'pages/ContractPage'
import DashboardPage from 'pages/DashboardPage'
import ForgotUsernamePage from 'pages/ForgotUsernamePage/ForgotUsernamePage'
import LoginEditPage from 'pages/LoginEditPage'
import MyDataPage from 'pages/MyDataPage'
import MyInvoicesPage from 'pages/MyInvoices'
import NewSepa from 'pages/NewSepa/NewSepa'
import OtherAddrEdit from 'pages/OtherAddrEdit'
import PersonalDataEdit from 'pages/PersonalDataEdit/PersonalDataEdit'
import ReactivateRegistration from 'pages/ReactivateRegistration'
import TipsDetails from 'pages/TipDetails'
import TipsOverview from 'pages/TipsOverview'
import ValidateRegistration from 'pages/ValidateRegistration'
import React, { Dispatch, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { Customize } from 'utils/customize/customize'
import getEnvConfig from 'utils/getEnvConfig'
import 'utils/i18n'
import PrivateRoute from 'utils/PrivateRoute'
import { ModifiedTheme } from 'utils/theme'
import { useApolloClient } from './utils/useApolloClient'

function App(): JSX.Element {
    const envConfig = getEnvConfig()
    const { t } = useTranslation()
    const graphQLServerURI =
        process.env.NODE_ENV !== 'production'
            ? `http://${envConfig.Domain}:${envConfig.HTTPListen}/graphql`
            : '/graphql'
    const [error, setError] = React.useState<ErrorConfig>()
    const { keycloak, initialized } = useKeycloak()
    const apolloClient = useApolloClient(graphQLServerURI, setError, keycloak)

    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()

    // load customize config
    useEffect(() => {
        //@ts-expect-error own declaration
        const dataFromJS: Customize = window.customize
        dispatch({ type: GeneralStateAction.SET_GENERAL_STATE_PARTIAL, payload: { customize: dataFromJS } })
        document.title = dataFromJS.general.title
    }, [])

    if (!initialized) {
        return <LoadingSpinner loading={true} />
    }

    return (
        <>
            {apolloClient && (
                <ApolloProvider client={apolloClient}>
                    <HashRouter>
                        <ThemeProvider theme={ModifiedTheme}>
                            <CssBaseline>
                                {error && <ErrorDialog errorConfig={error} />}
                                {!error && (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                                        <SnackbarProvider maxSnack={3}>
                                            <Switch>
                                                <Route exact path={'/forgotUsername'} component={ForgotUsernamePage} />
                                                <Route
                                                    exact
                                                    path={'/reactivateRegistration'} // reactivateRegistration
                                                    component={ReactivateRegistration}
                                                />
                                                <Route
                                                    exact
                                                    path={'/validateRegistration/:id/:key'} // validate registration on the crm
                                                    component={ValidateRegistration}
                                                />
                                                <PrivateRoute exact path={'/newSEPA'} component={NewSepa} />
                                                <PrivateRoute
                                                    exact
                                                    path={`/${t('url.dashboard')}`} // Ubersicht
                                                    component={DashboardPage}
                                                />

                                                <PrivateRoute
                                                    exact
                                                    path={`/${t('url.myData')}`} // Meine Daten
                                                    component={MyDataPage}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={`/${t('url.myInvoices')}`} // Meine Rechnungen
                                                    component={MyInvoicesPage}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={`/${t('url.tips')}`} // Tips
                                                    component={TipsOverview}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={`/${t('url.tips')}/:tipID?`} // Tips...
                                                    component={TipsDetails}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={`/${t('url.PersonalDataEdit')}`}
                                                    component={PersonalDataEdit}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={`/${t('url.BankDataEdit')}`}
                                                    component={BankDataEdit}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={`/${t('url.OtherAddrEdit')}`}
                                                    component={OtherAddrEdit}
                                                />
                                                <PrivateRoute exact path={`/contractDocs`} component={ContractPage} />
                                                <PrivateRoute
                                                    exact
                                                    path={`/contractOverview`}
                                                    component={ContractOverview}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={'/cancelContract/:contractID?'}
                                                    component={CancelContract}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={
                                                        '/cancelContractConfirmation/:contractID/:productName/:date/:targetEnd/:kind/:reason'
                                                    }
                                                    component={CancelContractConfirmation}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={`/${t('url.LoginEditPage')}`}
                                                    component={LoginEditPage}
                                                />
                                                <Route>
                                                    <Redirect to={`/${t('url.dashboard')}`} />
                                                </Route>
                                            </Switch>
                                        </SnackbarProvider>
                                    </MuiPickersUtilsProvider>
                                )}
                            </CssBaseline>
                        </ThemeProvider>
                    </HashRouter>
                </ApolloProvider>
            )}
        </>
    )
}

export default App
