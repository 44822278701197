import { TextField } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import TestIDs from 'utils/TestIDs'
import { getFormattedIbanByInput } from 'utils/utils'

export interface IbanInputProps {
    value: string
    onChange?: (iban: string) => void
    disabled?: boolean
}

const IbanInput = (props: IbanInputProps): JSX.Element => {
    const { onChange, value, disabled } = props
    const { t } = useTranslation()
    const iban = getFormattedIbanByInput(value)
    //const [iban, setIban] = React.useState<string>(getFormattedIbanByInput(value))

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const ibanInput = event.target.value
        const newIban = getFormattedIbanByInput(ibanInput)
        if (onChange) onChange(newIban)
    }

    return (
        <TextField
            fullWidth={true}
            disabled={disabled === true ? true : false}
            onChange={handleOnChange}
            value={iban}
            label={t('formLabels.iban')}
            variant={'filled'}
            {...TestIDs.GET_EDITABLE_BILLING_BANK_DATA('IBAN')}
        />
    )
}

export default IbanInput
