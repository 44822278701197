import { Box, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Typography } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import TestIDs from 'utils/TestIDs'

export interface ErrorBoxProps {
    errorsTitle?: string
    errors: string[]
}

const ErrorBox = (props: ErrorBoxProps): JSX.Element | null => {
    const { t } = useTranslation()

    const { errors, errorsTitle } = props

    if (errors.length === 0) return null

    return (
        <List className={'ErrorBox'} disablePadding {...TestIDs.GET_BASE('ERROR_BOX')}>
            {errorsTitle && (
                <ListSubheader className={'ErrorBoxSubheader'} component={'div'} disableGutters disableSticky>
                    <Typography variant={'subtitle1'}>{t('error.title.' + errorsTitle)}</Typography>
                </ListSubheader>
            )}
            {errors.map((error, index) => (
                <Box marginTop={index === 0 ? 0 : 2} key={index}>
                    <ListItem key={error + index}>
                        <ListItemIcon>
                            <FiberManualRecord />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>{t('error.message.' + error)}</Typography>
                        </ListItemText>
                    </ListItem>
                </Box>
            ))}
        </List>
    )
}

export default ErrorBox
