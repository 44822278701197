import { ApolloError, useLazyQuery } from '@apollo/client'
import { usePagination, UsePaginationItem } from '@material-ui/lab'
import { useKeycloak } from '@react-keycloak/web'
import { SearchOption } from 'components/SearchBar/SearchBar'
import { SEARCH_DOCS } from 'graphql/queries/SearchDocs'
import { Doc, Query, QuerySearchDocsArgs } from 'graphql/types'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store/store'
import { dlDoc } from 'utils/dldoc'
import { getNewOrder, SortOrder } from 'utils/utils'
import { ContractDocumentOrder } from './ContractPage'

export interface ContractState {
    loading: boolean
    searchTerms: SearchOption[]
    items: UsePaginationItem[]
    rows: Doc[]
    changeOrder: (field: ContractDocumentOrder) => void
    page: number
    perPage: number
    contractDateOrder: SortOrder
    contractNameOrder: SortOrder
    contractDisplayNameOrder: SortOrder
    downloadItems: (vi: number[]) => void
    selectedDocs: number[]
    selectOne: (index: number) => void
    selectAll: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const useContractState = (): ContractState => {
    const { searchTerms } = useSelector((appState: AppState) => ({
        searchTerms: appState.generalState.searchTerms,
    }))
    const [selectedDocs, setSelectedDocs] = useState<number[]>([])

    const { keycloak } = useKeycloak()
    const token = keycloak.token ? keycloak.token : ''
    const [rows, setRows] = useState<Doc[]>([])
    const [page, setPage] = useState(1)
    const perPage = 10
    const onPageChange = (_: any, page: number) => {
        //setSelected
        setPage(page)
    }

    const { items } = usePagination({
        count: rows.length % perPage === 0 ? Math.floor(rows.length / perPage) : Math.floor(rows.length / perPage) + 1,
        page: page,
        onChange: onPageChange,
    })

    const [contractDateOrder, setContractDateOrder] = useState<SortOrder>('desc')
    const [contractNameOrder, setContractNameOrder] = useState<SortOrder>(undefined)
    const [contractDisplayNameOrder, setContractDisplayNameOrder] = useState<SortOrder>(undefined)

    const changeOrder = (field: 'date' | 'name' | 'displayName') => {
        if (field === 'date') {
            const newDateOrder = getNewOrder(contractDateOrder)
            const sorted = _.orderBy(rows, ['lastModified'], [newDateOrder ? newDateOrder : false])
            setRows(sorted)
            setContractDateOrder(newDateOrder)
            return
        }
        if (field === 'name') {
            const newNameOrder = getNewOrder(contractNameOrder)
            const sorted = _.orderBy(
                rows,
                ['docType', 'lastModified'],
                [newNameOrder ? newNameOrder : false, contractDateOrder ? contractDateOrder : false],
            )
            setRows(sorted)
            setContractNameOrder(newNameOrder)
            return
        }
        if (field === 'displayName') {
            const newDisplayNameOrder = getNewOrder(contractDisplayNameOrder)
            const sorted = _.orderBy(
                rows,
                ['displayName'],
                [newDisplayNameOrder ? newDisplayNameOrder : false, contractDateOrder ? contractDateOrder : false],
            )
            setRows(sorted)
            setContractDisplayNameOrder(newDisplayNameOrder)
            return
        }
    }

    const [fetchDocs, { loading }] = useLazyQuery<Query>(SEARCH_DOCS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data: Query) => {
            if (data.searchDocs) {
                const manyDocs: Doc[] = []
                for (let i = 0; i < 1; i++) {
                    manyDocs.push(...data.searchDocs)
                }
                const sorted = _.orderBy(manyDocs, ['lastModified'], ['desc'])
                setRows(sorted)
            }
        },
        onError: (error: ApolloError) => console.log(error),
    })

    // download doc index will be passed here f.e. [0,1,2,3]
    const downloadItems = (vi: number[]) => {
        for (let i = 0; i < vi.length; i++) {
            const offset = (page - 1) * perPage
            dlDoc(rows[vi[i] + offset].path, rows[vi[i] + offset].bucket, token, rows[vi[i] + offset].downloadName)
        }
        contractDisplayNameOrder
        setSelectedDocs([])
    }

    const selectAll = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked) {
            // set soft limit to download 10 invoices at a time or the invoicesPerPage number
            const newSelecteds = rows.slice((page - 1) * perPage, perPage * page).map((_, i) => i)
            setSelectedDocs(newSelecteds)
            return
        }
        setSelectedDocs([])
    }

    const selectOne = (index: number) => {
        let k = -1
        for (let i = 0; i < selectedDocs.length; i++) {
            if (selectedDocs[i] === index) {
                k = i
                break
            }
        }
        if (k === -1) {
            selectedDocs.push(index)
        } else {
            selectedDocs.splice(k, 1)
        }
        setSelectedDocs([...selectedDocs])
    }

    useEffect(() => {
        const vars: QuerySearchDocsArgs = {
            docType: ['CONTRACT_SUMMARY', 'DOCUMENT', 'GENERAL'],
        }
        fetchDocs({ variables: vars })
    }, [])

    return {
        loading,
        searchTerms,
        rows,
        changeOrder,
        page,
        items,
        contractDateOrder,
        contractNameOrder,
        contractDisplayNameOrder,
        downloadItems,
        selectedDocs,
        selectOne,
        selectAll,
        perPage,
    }
}

export default useContractState
