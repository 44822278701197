import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'

export interface DialogComponentProps {
    open: boolean
    title: string
    content?: React.ReactElement
    labelAccept: string
    labelCancel?: string
    onCancel?: () => void
    onAccept: () => void
}

const DialogComponent = (props: DialogComponentProps): JSX.Element => {
    const { open, title, content, labelAccept, labelCancel, onCancel, onAccept } = props
    const hideCancelBtn: boolean = onCancel != undefined && labelCancel != undefined

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>
                <Box component={'span'} style={{ fontSize: 24, fontWeight: 'bolder' }}>
                    {title}
                </Box>
            </DialogTitle>
            {content && <DialogContent>{content}</DialogContent>}
            <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {hideCancelBtn && (
                    <Button variant={'outlined'} onClick={onCancel}>
                        {labelCancel}
                    </Button>
                )}
                <Button variant={'outlined'} className={hideCancelBtn ? 'primary' : ''} onClick={onAccept}>
                    {labelAccept}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogComponent
