import { SearchOption } from 'components/SearchBar/SearchBar'
import { FaqCategory, Loadstate, LoggedUser, TipsTricks, User } from 'graphql/types'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { Customize } from 'utils/customize/customize'

export const initialLoggedUser: LoggedUser = {
    id: '',
    email: '',
    roles: [],
    username: '',
}

export const initialLoadstate: Loadstate = {
    loading: false,
    errors: [],
}
export interface GeneralState {
    loadstate: Loadstate
    loggedUser: LoggedUser
    user: User | undefined
    faqList: FaqCategory[]
    tipsList: TipsTricks[]
    searchTerms: SearchOption[]
    customize: Customize | undefined
}

export const initialGeneralState: GeneralState = {
    loadstate: initialLoadstate,
    loggedUser: initialLoggedUser,
    user: undefined,
    faqList: [],
    tipsList: [],
    searchTerms: [],
    customize: undefined,
}

function GeneralStateReducer(state: GeneralState = initialGeneralState, action: GeneralStateActions): GeneralState {
    switch (action.type) {
        case GeneralStateAction.SET_LOADSTATE:
            return {
                ...state,
                loadstate: action.payload,
            }
        case GeneralStateAction.SET_LOGGED_USER:
            return {
                ...state,
                loggedUser: action.payload,
            }
        case GeneralStateAction.SET_USER:
            return {
                ...state,
                user: action.payload,
            }
        case GeneralStateAction.SET_FAQ_LIST:
            return {
                ...state,
                faqList: action.payload,
            }
        case GeneralStateAction.SET_TIPS_LIST:
            return {
                ...state,
                tipsList: action.payload,
            }
        case GeneralStateAction.SET_SEARCH_TERMS:
            return {
                ...state,
                searchTerms: action.payload,
            }

        case GeneralStateAction.SET_GENERAL_STATE_PARTIAL:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}

export default GeneralStateReducer
