import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { ErrorConfig } from 'graphql/types'
import React from 'react'

export interface ErrorDialogProps {
    errorConfig?: ErrorConfig
}

const ErrorDialog = (props: ErrorDialogProps): JSX.Element => {
    const ec = props.errorConfig

    return (
        <>
            {ec && (
                <Dialog
                    disableBackdropClick={false}
                    disableEscapeKeyDown={false}
                    aria-labelledby="customized-dialog-title"
                    open={true}
                >
                    <DialogTitle id="customized-dialog-title">{ec.title}</DialogTitle>
                    <DialogContent style={{ padding: '24px' }} dividers>
                        <Typography>{ec.content}</Typography>
                        {ec.messages && <Typography>{ec.messages}</Typography>}
                    </DialogContent>
                </Dialog>
            )}
        </>
    )
}

export default ErrorDialog
