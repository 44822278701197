import { Box, Button, Fade, Grid, TextField, Typography } from '@material-ui/core'
import CheckboxComponent from 'components/CheckboxComponent'
import ErrorBox from 'components/ErrorBox'
import FieldWrapper from 'components/FieldWrapper'
import ViewWrapperPublic from 'components/ViewWrapperPublic/ViewWrapperPublic'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'
import { getHeader } from 'utils/utils'
import { useValidateRegistration } from './useValidateRegistration'

const ValidationRegistration = (): JSX.Element => {
    const {
        success,
        password,
        setPassword,
        password2,
        setPassword2,
        loading,
        errorBox,
        history,
        showPass,
        setShowPass,
        submit,
        isLinkValid,
        isLinkExpired,
    } = useValidateRegistration()
    const { t } = useTranslation()

    return (
        <ViewWrapperPublic header={getHeader('', history, t, [])} loading={false}>
            <Box marginTop={'5%'} marginBottom={'15%'}>
                <Typography variant="h4" style={{ marginBottom: '10%' }}>
                    {t('pages.registration.title')}
                </Typography>
                <FieldWrapper text="">
                    <Grid container>
                        {!success && isLinkValid && !isLinkExpired && (
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="filled"
                                        fullWidth
                                        disabled={success}
                                        label={t('pages.registration.pass')}
                                        type={showPass ? 'text' : 'password'}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        inputProps={{
                                            style: { backgroundColor: success ? '' : colors.white },
                                            maxLength: 64,
                                        }}
                                        style={{ marginTop: 15 }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="filled"
                                        fullWidth
                                        disabled={success}
                                        label={t('pages.registration.pass2')}
                                        type={showPass ? 'text' : 'password'}
                                        value={password2}
                                        onChange={(e) => setPassword2(e.target.value)}
                                        inputProps={{
                                            style: { backgroundColor: success ? '' : colors.white },
                                            maxLength: 64,
                                        }}
                                        style={{ marginTop: 15 }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <CheckboxComponent
                                        checked={showPass}
                                        label={t('pages.registration.showPass')}
                                        onSelect={() => setShowPass(!showPass)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">{t('pages.registration.helper')}</Typography>
                                </Grid>
                            </Grid>
                        )}

                        {!success && !isLinkValid && (
                            <Fade in={!isLinkValid} timeout={1000}>
                                <Box flex={1} display="flex" justifyContent="center">
                                    <Typography variant="body1">{t('pages.registration.linkInvalidMsg')}</Typography>
                                </Box>
                            </Fade>
                        )}

                        {!success && isLinkExpired && (
                            <Fade in={isLinkExpired} timeout={1000}>
                                <Box flex={1} display="flex" justifyContent="center">
                                    <Typography variant="body1">{t('pages.registration.linkExpiredMsg')}</Typography>
                                </Box>
                            </Fade>
                        )}

                        {success && (
                            <Fade in={success} timeout={1000}>
                                <Box flex={1} display="flex" justifyContent="center">
                                    <Typography variant="body1">{t('pages.registration.successMsg')}</Typography>
                                </Box>
                            </Fade>
                        )}
                    </Grid>
                </FieldWrapper>

                <Fade in={errorBox.errors.length > 0} timeout={1000}>
                    <Grid item xs={12}>
                        <ErrorBox errorsTitle={''} errors={errorBox.errors} />
                    </Grid>
                </Fade>

                <Fade in={isLinkValid && !isLinkExpired} timeout={1000}>
                    <Box display="flex" justifyContent="flex-end" marginTop="5%">
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!isLinkValid || isLinkExpired || loading}
                            onClick={success ? () => history.push('/') : submit}
                        >
                            {!success && t('pages.registration.submit')}
                            {loading && <>...</>}
                            {success && t('pages.registration.toLogin')}
                        </Button>
                    </Box>
                </Fade>

                <Fade in={isLinkExpired} timeout={1000}>
                    <Box display="flex" flex={1} justifyContent={{ xs: 'center', sm: 'flex-end' }} marginTop="5%">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => history.push('/reactivateRegistration')}
                        >
                            {t('pages.registration.reactivateBtn')}
                        </Button>
                    </Box>
                </Fade>
            </Box>
        </ViewWrapperPublic>
    )
}

export default ValidationRegistration
