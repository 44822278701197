import { SearchOption } from 'components/SearchBar/SearchBar'
import { FaqCategory, User } from 'graphql/types'
import { Dispatch, useCallback, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useHistory } from 'react-router-dom'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { AppState } from 'store/store'
import { TipsTricks } from './../../graphql/types'

interface useTipsOverviewStateReturn {
    areFaqsCollapsable: boolean
    faqList: FaqCategory[]
    tipsList: TipsTricks[]
    user: User | undefined
    searchTerms: SearchOption[]
}

const useTipsOverviewState = (): useTipsOverviewStateReturn => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()
    // const history = useHistory()
    const { faqList, tipsList, user, searchTerms } = useSelector((appState: AppState) => ({
        faqList: appState.generalState.faqList,
        tipsList: appState.generalState.tipsList,
        user: appState.generalState.user,
        searchTerms: appState.generalState.searchTerms,
    }))

    const setFaqList = useCallback(
        (payload: FaqCategory[]) => {
            dispatch({
                type: GeneralStateAction.SET_FAQ_LIST,
                payload,
            })
        },
        [dispatch],
    )

    const setTipsList = useCallback(
        (payload: TipsTricks[]) => {
            dispatch({
                type: GeneralStateAction.SET_TIPS_LIST,
                payload,
            })
        },
        [dispatch],
    )

    const [areFaqsCollapsable, setFaqsCollapsable] = useState(false)

    useLayoutEffect(() => {
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

        // @ts-expect-error: Importing faqs_tips from public folder
        const faqs_tips: FAQS_TIPS = window.faqs_tips
        setFaqsCollapsable(faqs_tips.faqs_collapsable)
        setFaqList(faqs_tips.faqs)
        setTipsList(faqs_tips.tips_and_tricks)
    }, [])

    // useLayoutEffect(() => {
    //     if (!user) history.push('/')
    // }, [user])

    return {
        areFaqsCollapsable,
        faqList,
        tipsList,
        user,
        searchTerms,
    }
}

export default useTipsOverviewState
