import { useMutation } from '@apollo/client'
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { useKeycloak } from '@react-keycloak/web'
import FieldWrapper from 'components/FieldWrapper'
import ViewWrapper from 'components/ViewWrapper'
import { format } from 'date-fns'
import { SEND_CONTRACT_TERMINATION_AGAIN } from 'graphql/mutations/SendContractTerminationMailAgain'
import { Mutation, MutationSendContractTerminationMailAgainArgs } from 'graphql/types'
import { useSnackbar } from 'notistack'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { AppState } from 'store/store'
import { dlCancelContractPDF } from 'utils/dldoc'
import { getHeader, navigationLinks } from 'utils/utils'

const useStyles = makeStyles(() => ({
    customBtn: {
        padding: '14px 24px !important',
        marginBottom: 10,
    },
}))

type CancelContractConfirmationParams = {
    contractID: string
    productName: string
    date: string
    targetEnd: string
    kind: string
    reason: string
}

export const getTerminationText = (
    kind: string,
    /* eslint-disable */
    t: any,
    /* eslint-enable*/
    name: string,
    date: string,
    targetEnd: string,
    contractID: string,
    productName: string,
    reason: string,
    ordinary: string[],
    extraordinary: string[],
): string[] => {
    const cancelDate = format(new Date(Number(date)), 'dd.MM.yyyy')
    const cancelTime = format(new Date(Number(date)), 'HH:mm')
    const endDate = format(new Date(targetEnd), 'dd.MM.yyyy')
    const it: string[] = []

    const params = {
        name: name,
        cancelType: t('terminationType.' + kind),
        cancelDate: cancelDate,
        cancelTime: cancelTime,
        targetEnd: endDate,
        contractID: contractID,
        productName: productName,
        reason: reason,
    }

    if (kind === 'terminationForConvenience') {
        for (let i = 0; i < ordinary.length; i++) {
            it.push(t('terminationTxt.' + ordinary[i], params))
        }
    }
    if (kind === 'terminationForCause') {
        for (let i = 0; i < extraordinary.length; i++) {
            it.push(t('terminationTxt.' + extraordinary[i], params))
        }
    }
    return it
}

const CancelContractConfirmation = (): JSX.Element => {
    const classes = useStyles()
    const { keycloak } = useKeycloak()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const history = useHistory()
    const params = useParams<CancelContractConfirmationParams>()
    const { user, customize } = useSelector((appState: AppState) => ({
        user: appState.generalState.user,
        customize: appState.generalState.customize,
    }))

    const [sendMailAgain, { loading: loadingMail }] = useMutation<Mutation>(SEND_CONTRACT_TERMINATION_AGAIN, {
        onCompleted: (data) => {
            if (data.sendContractTerminationMailAgain) {
                enqueueSnackbar(t('notification.sendSuccess'), {
                    variant: 'success',
                })
            }
        },
    })

    const dlDetails = (): void => {
        if (user && txt.length > 0) {
            const cancelDate = format(new Date(Number(params.date)), 'dd.MM.yyyy')
            dlCancelContractPDF(
                keycloak.token ? keycloak.token : '',
                `Kündigungseingangsbestätigung-${decodeURIComponent(params.contractID)}-${cancelDate}.pdf`,
                txt,
            )
        }
    }

    const sendMail = (): void => {
        if (user) {
            const vars: MutationSendContractTerminationMailAgainArgs = {
                input: {
                    contractID: decodeURIComponent(params.contractID),
                    targetEnd: params.targetEnd,
                    kind: params.kind,
                    reason: decodeURIComponent(params.reason),
                    sendDate: Number(params.date),
                    email: user.email,
                    usernameFull: user.salutation + ' ' + user.firstName + ' ' + user.lastName,
                    articleDescription: decodeURIComponent(params.productName),
                    txt: txt,
                },
            }
            sendMailAgain({ variables: vars })
        }
    }
    const txt = useMemo(() => {
        if (user && customize) {
            const usernameFull = user.salutation + ' ' + user.firstName + ' ' + user.lastName
            return getTerminationText(
                params.kind,
                t,
                usernameFull,
                params.date,
                params.targetEnd,
                decodeURIComponent(params.contractID),
                decodeURIComponent(params.productName),
                decodeURIComponent(params.reason),
                customize.cancellation.ordinary,
                customize.cancellation.extraOrdinary,
            )
        }
        return []
    }, [params, user, t, customize])

    return (
        <ViewWrapper
            header={getHeader(`/${t('url.dashboard')}`, history, t, [])}
            navigation={navigationLinks('logged-in', 'contracts', t)}
        >
            <>
                <Typography variant={'h1'} style={{ marginBottom: 72 }}>
                    {t('pages.cancelConfirmation.title', {
                        contractID: decodeURIComponent(params.contractID),
                        productName: decodeURIComponent(params.productName),
                    })}
                </Typography>
                <Grid container justify="flex-start" alignItems="center" spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="body1">{t('pages.cancelConfirmation.info1')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">{t('pages.cancelConfirmation.info2')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">{t('pages.cancelConfirmation.info3')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{ marginBottom: 36 }}>
                            {t('pages.cancelConfirmation.info4')}
                        </Typography>
                    </Grid>
                </Grid>
                <FieldWrapper text={''}>
                    <Grid container justify="center" alignItems="center" spacing={3}>
                        {txt.map((f, i) => {
                            return (
                                <Grid item xs={12} key={i}>
                                    <Typography variant="body1">{f}</Typography>
                                </Grid>
                            )
                        })}
                        <Grid item xs={12}>
                            <Typography
                                dangerouslySetInnerHTML={{ __html: t('pages.cancelConfirmation.goodbye') }}
                                variant="body1"
                            />
                        </Grid>
                    </Grid>
                </FieldWrapper>
                <Box
                    marginTop={10}
                    marginBottom={10}
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent={'space-between'}
                >
                    <Button
                        className={classes.customBtn}
                        variant={'outlined'}
                        color={'default'}
                        onClick={() => {
                            history.goBack()
                        }}
                    >
                        {t('general.back')}
                    </Button>

                    <Button
                        className={classes.customBtn}
                        variant={'contained'}
                        color={'primary'}
                        onClick={sendMail}
                        disabled={loadingMail}
                    >
                        {t('pages.cancelConfirmation.resendMail')}
                    </Button>

                    <Button className={classes.customBtn} variant={'contained'} color={'primary'} onClick={dlDetails}>
                        {t('pages.cancelConfirmation.dl')}
                    </Button>
                </Box>
            </>
        </ViewWrapper>
    )
}

export default CancelContractConfirmation
