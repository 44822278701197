import { useKeycloak } from '@react-keycloak/web'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom'

interface PrivateRouteProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}

const PrivateRoute = React.memo(
    ({ component: Component, ...rest }: PrivateRouteProps): JSX.Element => {
        const { keycloak } = useKeycloak()
        useEffect(() => {
            if (keycloak.authenticated) {
                return
            }
            const loginFn = async (): Promise<void> => await keycloak.login()
            loginFn()
        }, [keycloak.authenticated])

        const render = (props: RouteComponentProps<any>): JSX.Element | null =>
            keycloak.authenticated ? <Component {...props} /> : null

        return <Route {...rest} render={render} />
    },
    (prevProps, nextProps) => {
        return _.isEqual(prevProps, nextProps)
    },
)

PrivateRoute.displayName = 'PrivateRoute'
export default PrivateRoute
