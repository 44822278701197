export interface GetTestIDReturn {
    [key: string]: string | undefined
}

export const getTestID = (id: string): GetTestIDReturn => {
    if (process.env.NODE_ENV === 'production') return {}
    return {
        'data-testid': id,
    }
}

type BASE = 'HEADER' | 'NAVIGATION_BAR' | 'USER_BAR' | 'FOOTER' | 'ERROR_BOX'
type AUTH_PAGES = 'HEADLINE' | 'FIELD_WRAPPER' | 'SUBMIT_BUTTON' | 'TEXT_FIELD'
type PERSONAL_DATA =
    | 'HEADLINE'
    | 'SUBLINE'
    | 'SALUTATION'
    | 'TITLE'
    | 'NAME'
    | 'LAST_NAME'
    | 'ZIP'
    | 'CITY'
    | 'STREET'
    | 'HOUSE_NUMBER'
    | 'EMAIL'
    | 'BIRTH_DATE'
    | 'TELEPHONE'
    | 'MOBILE_PHONE'
    | 'NEWSLETTER_MAIL'
    | 'NEWSLETTER_OFFER'
    | 'NEWSLETTER_TIPS'

type INVOICE_LIST_TABLE_DATA =
    | 'CHECKBOX_HEADER'
    | 'CHECKBOX_ROW'
    | 'TABLE_HEADER'
    | 'PDF_BUTTON'
    | 'DOWNLOAD_ALL_BUTTON'
    | 'PAGINATION_BUTTON'

const TestIDs = {
    GET_BASE: (input: BASE, category?: string): GetTestIDReturn =>
        getTestID('BASE_' + input + (category ? '_' + category : '')),
    GET_AUTH_PAGES: (input: AUTH_PAGES): GetTestIDReturn => getTestID('AUTH_PAGES_' + input),
    GET_LOGIN_PAGE: (input: 'USERNAME_INPUT' | 'PASSWORD_INPUT'): GetTestIDReturn => getTestID('LOGIN_PAGE_' + input),
    GET_REGISTRATION_PAGE: (
        input:
            | 'HEADLINE'
            | 'SUBLINE'
            | 'TEXT'
            | 'CUSTOMER_NUMBER_INPUT'
            | 'ONE_TIME_PASSWORD_INPUT'
            | 'PASSWORD_INPUT'
            | 'CONFIRM_PASSWORD_INPUT',
        category?: string,
    ): GetTestIDReturn => getTestID('REGISTRATION_PAGE_' + input + (category ? '_' + category : '')),
    GET_RESET_PASSWORD_PAGE: (
        input: 'SUBTITLE' | 'NEW_PASSWORD_INPUT' | 'NEW_PASSWORD_CONFIRMATION_INPUT',
    ): GetTestIDReturn => getTestID('RESET_PASSWORD_PAGE_' + input),
    GET_TWO_FACTOR_AUTHENTICATION_PAGE: (input: 'CODE_INPUT'): GetTestIDReturn =>
        getTestID('TWO_FACTOR_AUTHENTICATION_PAGE_' + input),
    GET_DASHBOARD_SECTION_BOX: (input: 'BOX' | 'TITLE' | 'BUTTON' | 'BUTTON2', category?: string): GetTestIDReturn =>
        getTestID('DASHBOARD_SECTION_BOX_' + input + (category ? '_' + category : '')),
    GET_DASHBOARD_PAGE: (input: 'HEADLINE' | 'SUBLINE' | 'TEXT' | 'ORDER_STATUS', category?: string): GetTestIDReturn =>
        getTestID('DASHBOARD_PAGE_' + input + (category ? '_' + category : '')),
    GET_REGISTRATION_PERSONAL_DATA_PAGE: (input: PERSONAL_DATA, category?: string): GetTestIDReturn =>
        getTestID('REGISTRATION_PERSONAL_DATA_PAGE_' + input + (category ? '_' + category : '')),
    GET_EDITABLE_PERSONAL_DATA: (
        input:
            | 'SALUTATION'
            | 'TITLE'
            | 'FIRST_NAME'
            | 'LAST_NAME'
            | 'STREET'
            | 'NUMBER'
            | 'ZIP'
            | 'CITY'
            | 'EMAIL'
            | 'BIRTHDATE'
            | 'SUBMIT_BUTTON'
            | 'CANCEL_BUTTON'
            | 'TELEPHONE'
            | 'COMPANY'
            | 'LEGAL_FORM'
            | 'MOBILE_PHONE',
    ): GetTestIDReturn => getTestID('GET_EDITABLE_MAIN_DATA_' + input),
    GET_EDITABLE_BILLING_BANK_DATA: (
        input: 'IBAN' | 'ACCOUNT_NUMBER' | 'BANK_CODE' | 'ACCOUNT_HOLDER' | 'ACCOUNT_TYPE',
        category?: string,
    ): GetTestIDReturn => getTestID('GET_EDITABLE_BILLING_BANK_DATA_' + input + (category ? '_' + category : '')),
    GET_EDITABLE_LOGIN: (
        input:
            | 'USERNAME'
            | 'OLD_PASSWORD'
            | 'NEW_PASSWORD_1'
            | 'NEW_PASSWORD_2'
            | 'SECURITY_ANSWER'
            | 'SECURITY_QUESTION'
            | 'TWO_FACTOR_AUTH',
    ): GetTestIDReturn => getTestID('GET_EDITABLE_LOGIN_' + input),
    GET_EDITABLE_NEWSLETTER: (input: 'EMAIL' | 'OFFERS' | 'TIPS'): GetTestIDReturn =>
        getTestID('GET_EDITABLE_NEWSLETTER_' + input),
    GET_MY_DATA_PAGE: (input: 'HEADLINE' | 'MY_DATA' | 'LOGIN' | 'NEWSLETTER'): GetTestIDReturn =>
        getTestID('GET_MY_DATA_PAGE_' + input),
    GET_TIPS_OVERVIEW: (
        input: 'HEADLINE' | 'BACK_TO_HELP_OVERVIEW' | 'TIP_ENTRY',
        category?: string,
    ): GetTestIDReturn => getTestID('GET_TIPS_OVERVIEW_' + input + (category ? '_' + category : '')),
    GET_TIPS_TRICKS_LIST: (input: 'TIPS_ENTRY' | 'TITLE', category?: string): GetTestIDReturn =>
        getTestID('GET_TIPS_TRICKS_LIST_' + input + (category ? '_' + category : '')),
    GET_HELP_OVERVIEW: (
        input: 'HEADLINE' | 'HEADLINE_FAQ' | 'TICKET_BOX' | 'CONTACT_BOX' | 'HELP_ENTRY',
        category?: string,
    ): GetTestIDReturn => getTestID('GET_HELP_OVERVIEW_' + input + (category ? '_' + category : '')),
    GET_HELP_DETAILS: (input: 'HEADLINE' | 'FAQ_LIST' | 'QUESTION' | 'ANSWER', id?: string): GetTestIDReturn =>
        getTestID('GET_HELP_DETAILS_' + input + (id ? '_' + id : '')),
    GET_TIPS_DETAILS: (input: 'HEADLINE'): GetTestIDReturn => getTestID('GET_TIPS_DETAILS_' + input),
    GET_MY_INVOICES_PAGE: (
        input: 'HEADLINE' | 'INVOICE_LIST' | 'BILLING_BANK_DATA' | 'BILLING_PERSONAL_DATA',
    ): GetTestIDReturn => getTestID('GET_MY_INVOICES_PAGE_' + input),
    GET_EDITABLE_WRAPPER: (input: 'EDIT' | 'CANCEL' | 'SAVE'): GetTestIDReturn =>
        getTestID('GET_EDITABLE_WRAPPER_' + input),
    GET_INVOICE_LIST_TABLE: (input: INVOICE_LIST_TABLE_DATA, category?: string): GetTestIDReturn =>
        getTestID('GET_INVOICE_LIST_TABLE_' + input + (category ? '_' + category : '')),
    GET_BANK_DATA: (element: 'EDIT_BUTTON'): GetTestIDReturn => getTestID('BANK_DATA-' + element),
    GET_LOGIN_DATA: (element: 'EDIT_BUTTON'): GetTestIDReturn => getTestID('LOGIN_DATA-' + element),
    GET_NEWSLETTER_DATA: (element: 'EDIT_BUTTON'): GetTestIDReturn => getTestID('NEWSLETTER_DATA-' + element),
    GET_OTHER_ADDR: (element: 'EDIT_BUTTON'): GetTestIDReturn => getTestID('OTHER_ADDR-' + element),
    GET_PERSONAL_DATA: (element: 'EDIT_BUTTON'): GetTestIDReturn => getTestID('PERSONAL_DATA-' + element),
    GET_NEW_SEPA: (element: 'CANCEL_BUTTON' | 'SUBMIT_BUTTON'): GetTestIDReturn => getTestID('NEW_SEPA-' + element),
    GET_BANK_DATA_EDIT: (
        element:
            | 'EDIT_BUTTON'
            | 'NEW_SEPA_BUTTON'
            | 'CHANGE_INVOICE_DISPATCH_BUTTON'
            | 'ACCOUNT_HOLDER'
            | 'IBAN'
            | 'BIC'
            | 'MANDATE_REF'
            | 'SALUTATION'
            | 'FNAME'
            | 'LNAME'
            | 'SRTEET'
            | 'HOUSE_NUMBER'
            | 'CITY'
            | 'ZIP'
            | 'CANCEL_BUTTON'
            | 'SUBMIT_BUTTON'
            | 'TITLE',
    ): GetTestIDReturn => getTestID('BANK_DATA_EDIT-' + element),
    GET_NEWSLETTER_EDIT: (element: 'EMAIL' | 'CANCEL_BUTTON' | 'SUBMIT_BUTTON' | 'OFFERS' | 'TIPS'): GetTestIDReturn =>
        getTestID('NEWSLETTER_EDIT-' + element),
    GET_LOGIN_EDIT: (element: 'ID' | 'MAIL' | 'PW_BUTTON' | 'SUBMIT_BUTTON' | 'CANCEL_BUTTON'): GetTestIDReturn =>
        getTestID('LOGIN_EDIT_PAGE-' + element),
    GET_OTHER_ADDR_EDIT: (
        element:
            | 'D-ACCOUNT_HOLDER'
            | 'D-SALUTATION'
            | 'D-TITLE'
            | 'D-FNAME'
            | 'D-LNAME'
            | 'D-STREET'
            | 'D-HOUSE_NUMBER'
            | 'D-CITY'
            | 'D-ZIP'
            | 'D-COUNTRY'
            | 'D-DELETE_BUTTON'
            | 'B-ACCOUNT_HOLDER'
            | 'B-SALUTATION'
            | 'B-TITLE'
            | 'B-FNAME'
            | 'B-LNAME'
            | 'B-STREET'
            | 'B-HOUSE_NUMBER'
            | 'B-CITY'
            | 'B-ZIP'
            | 'B-COUNTRY'
            | 'B-DELETE_BUTTON'
            | 'MODAL-DELETE_BUTTON'
            | 'CANCEL_BUTTON'
            | 'SAVE-BILLING'
            | 'SAVE-DELIVERY'
            | 'SUBMIT_BUTTON',
    ): GetTestIDReturn => getTestID('OTHER_ADDR_EDIT-' + element),
}

export default TestIDs
