import { gql } from '@apollo/client'

export const SUM_BILLS = gql`
    query getSumForBills($periodIDs: [String!]!) {
        getSumForBills(periodIDs: $periodIDs) {
            docID
            sumTotal {
                brutto
            }
        }
    }
`
