import { Backdrop, CircularProgress } from '@material-ui/core'
import React from 'react'

export interface LoadingSpinnerProps {
    loading: boolean
}

const LoadingSpinner = (props: LoadingSpinnerProps): JSX.Element => {
    const { loading } = props

    return (
        <Backdrop className={'LoadingSpinner'} open={loading} disableStrictModeCompat={true}>
            <CircularProgress className={'LoadingSpinner'} />
        </Backdrop>
    )
}

export default LoadingSpinner
