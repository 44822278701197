import {
    Box,
    Button,
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank, GetApp } from '@material-ui/icons'
import { UsePaginationItem } from '@material-ui/lab'
import CustomPagination from 'components/CustomPagination/CustomPagination'
import { SortIcon } from 'components/InvoiceListDesktop/InvoiceListDesktop'
import { format } from 'date-fns'
import { Doc } from 'graphql/types'
import _ from 'lodash'
import { ContractDocumentOrder } from 'pages/ContractPage/ContractPage'
import React from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'
import { getCurrentItems } from 'utils/getCurrentItems'
import { SortOrder } from 'utils/utils'
import { useHistory } from 'react-router-dom'

export interface ContractDocumentTableProps {
    rows: Doc[]
    changeOrder: (field: ContractDocumentOrder) => void
    page: number
    perPage: number
    contractDateOrder: SortOrder
    contractNameOrder: SortOrder
    contractDisplayNameOrder: SortOrder
    items: UsePaginationItem[]
    selectedDocs: number[] // index of item no need to copy
    selectOne: (index: number) => void
    selectAll: (e: React.ChangeEvent<HTMLInputElement>) => void
    downloadItems: (vi: number[]) => void
}

const ContractDocumentTable = (props: ContractDocumentTableProps): JSX.Element => {
    const {
        rows,
        changeOrder,
        contractDateOrder,
        page,
        perPage,
        contractNameOrder,
        contractDisplayNameOrder,
        items,
        selectedDocs,
        selectOne,
        selectAll,
        downloadItems,
    } = props
    const { t } = useTranslation()
    const history = useHistory()
    const itemMeta = getCurrentItems(page, perPage, rows.length)
    return (
        <>
            <Box width={1} marginBottom={9}>
                <Box>
                    <Typography variant="h1">{t('pages.myContracts.title')}</Typography>
                    <Box marginTop={1}>
                        <Typography variant="body1">{t('pages.myContracts.subtitle')}</Typography>
                    </Box>
                </Box>
            </Box>

            <Box
                width={1}
                flex={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
            >
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" style={{ minWidth: 700, overflowX: 'scroll' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color={'primary'}
                                        icon={<CheckBoxOutlineBlank style={{ color: colors.midDarkerGray }} />}
                                        checkedIcon={<CheckBox style={{ color: colors.midDarkerGray }} />}
                                        checked={rows.length > 0 && selectedDocs.length === itemMeta.nItems}
                                        onChange={selectAll}
                                        inputProps={{ 'aria-label': 'select all invoice' }}
                                    />
                                </TableCell>
                                <TableCell padding="default">
                                    <TableSortLabel
                                        IconComponent={SortIcon}
                                        active={contractDateOrder !== undefined}
                                        direction={contractDateOrder}
                                        onClick={() => changeOrder('date')}
                                        color={'primary'}
                                    >
                                        <Typography
                                            variant={'body2'}
                                            style={{ color: colors.midDarkerGray, wordBreak: 'break-word' }}
                                        >
                                            {t('documentTable.date')}
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right" padding="default">
                                    <TableSortLabel
                                        IconComponent={SortIcon}
                                        active={contractNameOrder !== undefined}
                                        direction={contractNameOrder}
                                        onClick={() => changeOrder('name')}
                                        color={'primary'}
                                    >
                                        <Typography
                                            variant={'body2'}
                                            style={{ color: colors.midDarkerGray, wordBreak: 'break-word' }}
                                        >
                                            {t('documentType')}
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right" padding="default" style={{ width: '250px' }}>
                                    <TableSortLabel
                                        IconComponent={SortIcon}
                                        active={contractDisplayNameOrder !== undefined}
                                        direction={contractDisplayNameOrder}
                                        onClick={() => changeOrder('displayName')}
                                        color={'primary'}
                                    >
                                        <Typography
                                            variant={'body2'}
                                            style={{ color: colors.midDarkerGray, wordBreak: 'break-word' }}
                                        >
                                            {t('documentTable.name')}
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right" padding="default"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.slice(rows, (page - 1) * 10, page * 10).map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        onClick={(e): void => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            selectOne(index)
                                        }}
                                        role="checkbox"
                                        aria-checked={true}
                                        tabIndex={-1}
                                        selected={true}
                                        key={row.path + index}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedDocs.includes(index)}
                                                icon={<CheckBoxOutlineBlank style={{ color: colors.midDarkerGray }} />}
                                                checkedIcon={<CheckBox style={{ color: colors.midDarkerGray }} />}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                    selectOne(index)
                                                }}
                                                color={'primary'}
                                            />
                                        </TableCell>
                                        <TableCell padding="default">
                                            <Typography variant="body1">
                                                {row.lastModified.trim() !== ''
                                                    ? format(new Date(row.lastModified), 'dd.MM.yy')
                                                    : ''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right" padding="default">
                                            <Typography variant="body1">{t('docType.' + row.docType)}</Typography>
                                        </TableCell>
                                        <TableCell align="right" padding="default">
                                            <Typography variant="body1">{row.displayName}</Typography>
                                        </TableCell>
                                        <TableCell align="right" padding="default">
                                            <Button
                                                startIcon={<GetApp />}
                                                color="primary"
                                                variant="outlined"
                                                style={{ border: '2px solid', maxWidth: 10 }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                    downloadItems([index])
                                                }}
                                            >
                                                PDF
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Button
                    variant={'contained'}
                    color={'primary'}
                    disabled={selectedDocs.length === 0}
                    style={{ marginTop: '16px', marginBottom: '8px', alignSelf: 'flex-end' }}
                    onClick={() => downloadItems(selectedDocs)}
                >
                    {t('pages.myInvoices.invoiceList.download')}
                </Button>
            </Box>
            <CustomPagination items={items} />

            <Box
                marginTop={5}
                marginBottom={5}
                display={'flex'}
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent={'space-between'}
            >
                <Button variant={'outlined'} color={'default'} onClick={() => history.goBack()}>
                    {t('general.back')}
                </Button>
            </Box>
        </>
    )
}

export default ContractDocumentTable
