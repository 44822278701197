import { SearchOption } from 'components/SearchBar/SearchBar'
import { FaqCategory, TipsTricks, User } from 'graphql/types'
import { Dispatch, useCallback, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { AppState } from 'store/store'
import { FAQS_TIPS, translateSearchTerms } from 'utils/utils'

interface UseDashboardStateReturn {
    //orderStatus: OrderStatus[]
    //invoices: Invoice[]
    user: User | undefined
    searchTerms: SearchOption[]
}

const useDashboardState = (): UseDashboardStateReturn => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()
    // const history = useHistory()

    const { t } = useTranslation()
    //const [orderStatus, setOrderStatus] = useState<OrderStatus[]>([])

    const { user, searchTerms } = useSelector((appState: AppState) => ({
        user: appState.generalState.user,
        searchTerms: appState.generalState.searchTerms,
    }))

    const setFaqList = useCallback(
        (payload: FaqCategory[]) => {
            dispatch({
                type: GeneralStateAction.SET_FAQ_LIST,
                payload,
            })
        },
        [dispatch],
    )

    const setTipsList = useCallback(
        (payload: TipsTricks[]) => {
            dispatch({
                type: GeneralStateAction.SET_TIPS_LIST,
                payload,
            })
        },
        [dispatch],
    )

    //const setInvoices = useCallback(
    //(payload: Invoice[]) => {
    //dispatch({
    //type: GeneralStateAction.SET_INVOICES,
    //payload,
    //})
    //},
    //[dispatch],
    //)

    const setSearchTerms = useCallback(
        (payload: SearchOption[]) => {
            dispatch({
                type: GeneralStateAction.SET_SEARCH_TERMS,
                payload,
            })
        },
        [dispatch],
    )

    //const [getOrderStatus] = useLazyQuery(GetOrderStatus, {
    //fetchPolicy: 'cache-and-network',
    //onCompleted: (data: any) => {
    //if (data && data.getOrderStatus) {
    //setOrderStatus(data.getOrderStatus)
    //}
    //},
    //})

    useLayoutEffect(() => {
        // @ts-expect-error: Importing faqs_tips from public folder
        const faqs_tips: FAQS_TIPS = window.faqs_tips
        // @ts-expect-error: Importing searchTerms from public folder
        const searchTerms: SearchOption[] = window.searchTerms.options

        setSearchTerms(translateSearchTerms(searchTerms, t))
        setFaqList(faqs_tips.faqs)
        setTipsList(faqs_tips.tips_and_tricks)
        //getOrderStatus()
        //getInvoices()
    }, [])

    // useLayoutEffect(() => {
    //     if (!user) history.push('/')
    // }, [user])

    return {
        //orderStatus,
        //invoices,
        user,
        searchTerms,
    }
}

export default useDashboardState
