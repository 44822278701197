import { SearchOption } from 'components/SearchBar/SearchBar'
import { Dispatch, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { AppState } from 'store/store'
import { FAQS_TIPS } from 'utils/utils'
import { FaqCategory, TipsTricks, User } from '../../graphql/types'

interface useTipsOverviewStateReturn {
    currentTip: TipsTricks | undefined
    tipsList: TipsTricks[]
    user: User | undefined
    searchTerms: SearchOption[]
}

const useTipsDetailsState = (tipID: string | undefined): useTipsOverviewStateReturn => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()

    const { tipsList, user, searchTerms } = useSelector((appState: AppState) => ({
        tipsList: appState.generalState.tipsList,
        user: appState.generalState.user,
        searchTerms: appState.generalState.searchTerms,
    }))
    const [currentTip, setCurrentTip] = useState<TipsTricks>()

    const setFaqList = useCallback(
        (payload: FaqCategory[]) => {
            dispatch({
                type: GeneralStateAction.SET_FAQ_LIST,
                payload,
            })
        },
        [dispatch],
    )

    const setTipsList = useCallback(
        (payload: TipsTricks[]) => {
            dispatch({
                type: GeneralStateAction.SET_TIPS_LIST,
                payload,
            })
        },
        [dispatch],
    )

    useLayoutEffect(() => {
        // @ts-expect-error: Importing faqs_tips from public folder
        const faqs_tips: FAQS_TIPS = window.faqs_tips
        if (tipsList.length === 0) {
            setFaqList(faqs_tips.faqs)
            setTipsList(faqs_tips.tips_and_tricks)
        }
        const currentTip = faqs_tips.tips_and_tricks.find((tip) => {
            return tip.id === tipID
        })
        if (currentTip) {
            setCurrentTip(currentTip)
        }
    })

    useEffect(() => {
        window.scrollTo({ top: 0 })
    })

    return {
        currentTip,
        tipsList,
        user,
        searchTerms,
    }
}

export default useTipsDetailsState
