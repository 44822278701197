import { Box, Button, Fade, Grid, Link, TextField, Typography } from '@material-ui/core'
import FieldWrapper from 'components/FieldWrapper'
import ViewWrapperPublic from 'components/ViewWrapperPublic/ViewWrapperPublic'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getHeader } from 'utils/utils'
import { useForgotUsername } from './useForgotUsername'

const ForgotUsernamePage = (): JSX.Element => {
    const { success, loading, history, email, setEmail, requestUsername } = useForgotUsername()
    const { t } = useTranslation()

    return (
        <ViewWrapperPublic header={getHeader(`/${t('url.dashboard')}`, history, t, [])} loading={false}>
            <Box marginTop={'5%'} marginBottom={'15%'}>
                <FieldWrapper text="">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h4">{t('pages.forgotUsername.title')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">{t('pages.forgotUsername.help1')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="filled"
                                fullWidth
                                label={t('pages.forgotUsername.email')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                inputProps={{
                                    style: { backgroundColor: 'white' },
                                }}
                                style={{ marginTop: 15 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Link href="#" style={{ color: 'black' }}>
                                <Typography style={{ paddingTop: 10, paddingBottom: 10 }} variant="body1">
                                    {t('pages.forgotUsername.backToLogin')}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading || success === true || success === false}
                                onClick={requestUsername}
                            >
                                {t('pages.forgotUsername.submit')}
                                {loading && <>...</>}
                            </Button>
                        </Grid>
                        {success === true && (
                            <Fade in={success} timeout={1000}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ marginTop: 15 }}>
                                        {t('pages.forgotUsername.messageSend')}
                                    </Typography>
                                </Grid>
                            </Fade>
                        )}
                        {success === false && (
                            <Fade in={success} timeout={1000}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ marginTop: 15 }}>
                                        {t('pages.forgotUsername.messageFail')}
                                    </Typography>
                                </Grid>
                            </Fade>
                        )}
                    </Grid>
                </FieldWrapper>
            </Box>
        </ViewWrapperPublic>
    )
}

export default ForgotUsernamePage
