import { gql } from '@apollo/client'

export const CHECK_LINK = gql`
    query checkLink($input: IsValidRequest!) {
        checkLink(input: $input) {
            isValid
            isExipired
        }
    }
`
