import gql from 'graphql-tag'

export const GET_CONTRACT_OVERVIEW = gql`
    query contractOverview($contractID: String!) {
        getContractOverview(contractID: $contractID) {
            contractID
            datasheetLink
            inet {
                ...packageFields
            }
            tv {
                ...packageFields
            }
            phone {
                ...packageFields
            }
        }
    }

    fragment packageFields on Package {
        periodStart
        periodEnd
        earliestCancellationDate
        serviceDescription
        baseTypeID
        baseTypeName
        Products {
            ...productFields
        }
        Hardware {
            ...productFields
        }
    }

    fragment productFields on Product {
        name
        monthlyPrice
        phoneNumbers
        inetSpeeds {
            DownMax
            DownStd
            DownMin
            UpMax
            UpStd
            UpMin
        }
        Options {
            name
            monthlyPrice
            phoneNumbers
            inetSpeeds {
                DownMax
                DownStd
                DownMin
                UpMax
                UpStd
                UpMin
            }
        }
    }
`
