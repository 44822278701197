import {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
    validatePhoneNumberLength,
    ValidatePhoneNumberLengthResult,
} from 'libphonenumber-js'

export type PhoneValidation = {
    isValid: boolean
    isPossible: boolean
    isValidLength: ValidatePhoneNumberLengthResult | undefined
}

export const isValidPhone = (s: string): PhoneValidation => {
    const isValid = isValidPhoneNumber(s, 'DE')
    const isPossible = isPossiblePhoneNumber(s, 'DE')
    const isValidLength = validatePhoneNumberLength(s, 'DE')
    return { isValid, isPossible, isValidLength }
}

const pregex = new RegExp('^[+]?[\\d\\s]*$')
export const isPhoneRegex = (s: string): boolean => {
    return pregex.test(s)
}

export const checkPhoneErrors = (p: PhoneValidation, prefix: string): string[] => {
    const newErrors: string[] = []
    if (!p.isValid) {
        newErrors.push(prefix + 'NOT_VALID')
    }
    if (!p.isPossible) {
        newErrors.push(prefix + 'NOT_POSSIBLE')
    }
    if (p.isValidLength !== undefined) {
        newErrors.push(prefix + p.isValidLength)
    }
    return newErrors
}
