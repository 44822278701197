import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import * as React from 'react'
import { GetTestIDReturn } from 'utils/TestIDs'
import { DropdownOption, Salutation } from 'utils/utils'

export interface DropdownProps {
    disabled?: boolean
    error?: boolean
    focused?: boolean
    small?: boolean
    handleChange: (
        event: React.ChangeEvent<{
            name?: string
            value: unknown
        }>,
        child: React.ReactNode,
    ) => void
    label: string
    options: DropdownOption[]
    selectedOption: any
    testIdentifier?: GetTestIDReturn
    id?: string
}

const Dropdown = (props: DropdownProps): JSX.Element => {
    const { disabled, error, focused, handleChange, label, options, selectedOption, small, testIdentifier, id } = props

    const iconComponent = (e: React.HTMLAttributes<HTMLImageElement>): JSX.Element => (
        <img
            className={e.className}
            alt={'button chevron'}
            style={{ marginRight: 16, marginTop: 8 }}
            src={process.env.PUBLIC_URL + '/customize/icons/dropdown.svg'}
        />
    )

    return (
        <FormControl
            {...testIdentifier}
            margin={small ? 'dense' : 'none'}
            variant={'filled'}
            fullWidth
            error={error}
            disabled={disabled}
            focused={focused}
        >
            <InputLabel>{label}</InputLabel>
            <Select id={id} value={selectedOption} onChange={handleChange} IconComponent={iconComponent}>
                {options.map((option, index) => (
                    <MenuItem
                        disabled={option.value === Salutation.NONE}
                        key={index + option.value}
                        value={option.value}
                    >
                        <Typography>{option.label}</Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default Dropdown
