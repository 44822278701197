import { Box, Button, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import colors from 'utils/colors'
import TestIDs from 'utils/TestIDs'

const OtherAddr = (): JSX.Element => {
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <Box padding={{ xs: 2, sm: 5 }} bgcolor={colors.lightGray} height="100%">
            <Box marginBottom={5}>
                <Typography variant={'h2'}>{t('pages.myData.OtherAddr.title')}</Typography>
            </Box>
            <Box margin={2}>
                <Typography variant={'body1'}>{t('pages.myData.OtherAddr.helper')}</Typography>
            </Box>

            <Box display="flex" flex={1} justifyContent="flex-end">
                <Button
                    variant={'outlined'}
                    onClick={() => history.push(t('url.OtherAddrEdit'))}
                    TouchRippleProps={{ style: { color: colors.main } }}
                    {...TestIDs.GET_OTHER_ADDR('EDIT_BUTTON')}
                >
                    {t('pages.myData.viewAddr')}
                </Button>
            </Box>
        </Box>
    )
}

export default OtherAddr
