export type cItems = {
    start: number
    end: number
    nItems: number
}
export const getCurrentItems = (currentPage: number, perPage: number, total: number): cItems => {
    const start = (currentPage - 1) * perPage
    const end = currentPage * perPage
    let nItems = total
    if (end > nItems) {
        nItems -= start
    } else if (end < nItems && total !== 0) {
        nItems = perPage
    }
    return { start, end, nItems }
}
