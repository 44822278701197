export const dlDoc = async (fileName: string, bucket: string, token: string, downloadName: string): Promise<void> => {
    const res = await fetch('/doc/' + fileName + '/' + bucket, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            authorization: `Bearer ${token}`,
        },
    })
    if (res.status === 200) {
        const blob = await res.blob()
        downloadBlob(downloadName, blob)
    } else {
        console.log(res)
    }
}

const downloadBlob = (fileName: string, file: Blob): void => {
    const element = document.createElement('a')
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element)
    element.click()
    if (element.parentElement) {
        element.parentElement.removeChild(element)
    }
}

export const dlCancelContractPDF = async (token: string, downloadName: string, txt: string[]): Promise<void> => {
    const params = txtToParams(txt)
    const res = await fetch(`/cancelContract${params}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            authorization: `Bearer ${token}`,
        },
    })
    if (res.status === 200) {
        const blob = await res.blob()
        downloadBlob(downloadName, blob)
    } else {
        console.log(res)
    }
}

const txtToParams = (vs: string[]): string => {
    let params = '?'
    for (let i = 0; i < vs.length; i++) {
        params += 'txt=' + vs[i]
        if (i !== vs.length - 1) {
            params += '&'
        }
    }
    return params
}
