import { HeaderProps } from 'components/Header/Header'
import { NavigationBarProps } from 'components/NavigationBar/NavigationBar'
import { SearchOption } from 'components/SearchBar/SearchBar'
import { UserBarProps } from 'components/UserBar/UserBar'
import { isBefore, parse, sub } from 'date-fns'
import { saveAs } from 'file-saver'
import { Address, FaqCategory, Invoice, TipsTricks, User, UserInput } from 'graphql/types'
import * as H from 'history'
import { TFunction } from 'i18next'
import JSZip from 'jszip'
import JSZipUtils from 'utils/JsZipUtils'

export type SortOrder = 'desc' | 'asc' | undefined

export enum Salutation {
    NONE = 'none',
    MR = 'Herr',
    MRS = 'Frau',
    DIVERSE = 'Divers',
    EHELEUTE = 'Eheleute',
    FRAU_UND_HERR = 'Frau und Herr',
    HERR_UND_FRAU = 'Herr und Frau',
    FAMILY = 'Familie',
    HERR_UND_HERR = 'Herr und Herr',
    FRAU_UND_FRAU = 'Frau und Frau',
}

export enum CountryOpts {
    NONE = 'none',
    DEL = 'delete',
    GER = 'Deutschland',
}

export interface FAQS_TIPS {
    faqs_collapsable: boolean
    faqs: FaqCategory[]
    tips_and_tricks: TipsTricks[]
}

export interface DropdownOption {
    label: string
    value: any
}

export const dropdownOptionsCountry = (t: TFunction): DropdownOption[] => [
    {
        label: t('formLabels.countryType.' + CountryOpts.NONE),
        value: CountryOpts.NONE,
    },
    {
        label: t('formLabels.countryType.' + CountryOpts.GER),
        value: CountryOpts.GER,
    },
]

export const dropdownOptionsSalutation = (t: TFunction): DropdownOption[] => [
    {
        label: t('formLabels.salutationType.' + Salutation.NONE),
        value: Salutation.NONE,
    },
    {
        label: t('formLabels.salutationType.' + Salutation.MR),
        value: Salutation.MR,
    },
    {
        label: t('formLabels.salutationType.' + Salutation.MRS),
        value: Salutation.MRS,
    },
    {
        label: t('formLabels.salutationType.' + Salutation.DIVERSE),
        value: Salutation.DIVERSE,
    },
    {
        label: t('formLabels.salutationType.' + Salutation.EHELEUTE),
        value: Salutation.EHELEUTE,
    },

    {
        label: t('formLabels.salutationType.' + Salutation.FRAU_UND_HERR),
        value: Salutation.FRAU_UND_HERR,
    },

    {
        label: t('formLabels.salutationType.' + Salutation.HERR_UND_FRAU),
        value: Salutation.HERR_UND_FRAU,
    },
    {
        label: t('formLabels.salutationType.' + Salutation.FRAU_UND_FRAU),
        value: Salutation.FRAU_UND_FRAU,
    },
    {
        label: t('formLabels.salutationType.' + Salutation.HERR_UND_HERR),
        value: Salutation.HERR_UND_HERR,
    },
]

export const navigationLinks = (
    stage: 'reactivate' | 'registering' | 'not-logged-in' | 'logged-in',
    currentPage: string,
    t: TFunction,
): NavigationBarProps => {
    switch (stage) {
        case 'reactivate':
            return {
                links: [{ label: 'login', href: '/reactivateRegistration' }],
                selectedLink: currentPage,
            }
        case 'registering':
            return {
                links: [{ label: 'registration', href: `/${t('url.registration')}` }],
                selectedLink: currentPage,
            }
        case 'not-logged-in':
            return { links: [{ label: 'login', href: `/${t('url.login')}` }], selectedLink: currentPage }
        case 'logged-in':
            return {
                links: [
                    { label: 'dashboard', href: `/${t('url.dashboard')}` },
                    { label: 'myData', href: `/${t('url.myData')}` },
                    { label: 'myInvoices', href: `/${t('url.myInvoices')}` },
                    { label: 'contracts', href: `/contractOverview` },
                    // { label: 'help', href: `/${t('url.help')}` },
                ],
                selectedLink: currentPage,
            }
    }
}

export const getHeader = (
    pageToLinkIconTo: string,
    history: H.History,
    t: TFunction,
    searchTerms: SearchOption[],
): HeaderProps => {
    if (pageToLinkIconTo === '') {
        return {
            helpNumber: 'https://www.fiber-network.de/kontakt/',
            onLogoClick: (): void => history.push('/'),
        }
    }

    return {
        searchBarProps: {
            options: searchTerms,
        },
        helpNumber: 'https://www.fiber-network.de/kontakt/',
        onLogoClick: (): void => history.push('/'),
    }
}

export const getUserBar = (history: H.History, user?: User): UserBarProps => ({
    onLogoutClick: (): void => history.push('/'),
    user: user ? (user.customerID !== '' ? user.customerID : 'test') : '',
})

export const deleteMissingField = (field: string, array: string[]): string[] => {
    const arrayToReturn = [...array]
    const index = arrayToReturn.indexOf(field)
    if (index !== -1) {
        arrayToReturn.splice(index, 1)
    }
    return arrayToReturn
}

export const isPersonalDataFormCorrect = (user: User): { status: boolean; errors: string[] } => {
    let missingFields = [
        'salutation',
        'firstName',
        'lastName',
        'street',
        'houseNumber',
        'zipCode',
        'city',
        'email',
        'birthdate',
        'telephone',
        'mobile',
    ]

    const errors: string[] = []

    if (user.salutation !== Salutation.NONE) {
        missingFields = deleteMissingField('salutation', missingFields)
    }

    if (user.firstName.length > 0) {
        missingFields = deleteMissingField('firstName', missingFields)
    }

    if (user.lastName.length > 0) {
        missingFields = deleteMissingField('lastName', missingFields)
    }

    if (user.connectionAddress.street.length > 0) {
        missingFields = deleteMissingField('street', missingFields)
    }

    if (user.connectionAddress.houseNumber.length > 0) {
        missingFields = deleteMissingField('houseNumber', missingFields)
    }

    if (user.connectionAddress.zipCode.length === 5) {
        missingFields = deleteMissingField('zipCode', missingFields)
        const zipCodeRegExp = /^[0-9]{5}$/
        if (!zipCodeRegExp.test(user.connectionAddress.zipCode)) {
            errors.push('zipCode')
        }
    }

    if (user.connectionAddress.city.length > 0) {
        missingFields = deleteMissingField('city', missingFields)
    }

    if (user.email.length > 0) {
        const emailRegExp =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const emailToCheck = user.email.replace(/ä/g, 'ae').replace(/ö/g, 'oe').replace(/ü/g, 'ue').replace(/ß/g, 'ss')
        if (!emailRegExp.test(emailToCheck)) {
            errors.push('email')
        }
        missingFields = deleteMissingField('email', missingFields)
    }

    if (user.birthdate.length > 0) {
        const date = parse(user.birthdate, 'dd.MM.yyyy', new Date())
        if (!isNaN(date.getTime())) {
            const maxDate = sub(new Date(), {
                years: 18,
                months: 0,
                weeks: 0,
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            })
            if (!isBefore(date, maxDate)) {
                errors.push('birthdate_underage')
            }
            missingFields = deleteMissingField('birthdate', missingFields)
        } else {
            errors.push('birthdate_invalidFormat')
        }
    }

    if (user.telephone.length > 0) {
        const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g
        if (!phoneRegExp.test(user.telephone)) {
            errors.push('telephone')
        }
        missingFields = deleteMissingField('telephone', missingFields)
    }

    if (user.mobile.length > 0) {
        const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g
        if (!phoneRegExp.test(user.mobile)) {
            errors.push('mobile')
        }
        missingFields = deleteMissingField('mobile', missingFields)
    }

    if (user.offers || user.tips) {
        if (user.newsletterEmail && user.newsletterEmail.length > 0) {
            const emailRegExp =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            const emailToCheck = user.newsletterEmail
                .replace(/ä/g, 'ae')
                .replace(/ö/g, 'oe')
                .replace(/ü/g, 'ue')
                .replace(/ß/g, 'ss')
            if (!emailRegExp.test(emailToCheck)) {
                errors.push('newsletter_email')
            }
        } else {
            missingFields.push('newsletter_email')
        }
    }

    return { status: missingFields.length === 0, errors }
}

export const formatIban = (iban: string): string => {
    if (!iban) {
        return ''
    }

    const cleanIban = iban
        .replace(/\s\s+/g, ' ')
        .replace(/[^0-9a-zA-Z]/gi, '')
        .toUpperCase()

    const parts = []

    if (cleanIban.length > 0) {
        parts.push(cleanIban.substring(0, 4))
    }

    if (cleanIban.length > 4) {
        parts.push(cleanIban.substring(4, 8))
    }

    if (cleanIban.length > 8) {
        parts.push(cleanIban.substring(8, 12))
    }

    if (cleanIban.length > 12) {
        parts.push(cleanIban.substring(12, 16))
    }

    if (cleanIban.length > 16) {
        parts.push(cleanIban.substring(16, 20))
    }

    if (cleanIban.length > 20) {
        parts.push(cleanIban.substring(20, 22))
    }

    return parts.join(' ')
}

export const getFormattedIbanByInput = (input: string): string => {
    const iban = input.replace(/[^0-9a-zA-Z]/gi, '').toUpperCase()

    return formatIban(iban)
}

export const downloadMultipleFiles = (invoices: Invoice[]): void => {
    const zip = new JSZip()
    let count = 0
    const zipFilename = 'zip.zip'
    if (invoices.length > 1) {
        invoices.forEach((invoice, index) => {
            const filename =
                invoice.date +
                '_' +
                invoice.name +
                (invoice.billingPeriod ? '_' + invoice.billingPeriod : '') +
                '_' +
                index +
                '.pdf'
            JSZipUtils.getBinaryContent(invoice.downloadURL, (error, data) => {
                if (error) {
                    throw error
                }
                zip.file(filename, data, { binary: true })
                if (++count == invoices.length) {
                    zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
                        saveAs(content, zipFilename)
                    })
                }
            })
        })
    } else {
        const invoice = invoices[0]
        fetch(invoice.downloadURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                'Sec-Fetch-Mode': 'cors',
                'Sec-Fetch-Site': 'same-origin',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                    'download',
                    invoice.date +
                        '_' +
                        invoice.name +
                        (invoice.billingPeriod ? '_' + invoice.billingPeriod : '') +
                        '.pdf',
                )

                // Append to html link element page
                document.body.appendChild(link)

                // Start download
                link.click()

                // Clean up and remove the link
                if (link.parentNode) {
                    link.parentNode.removeChild(link)
                }
            })
    }
}
// TODO: implement when UserInput is reworked
export const getUserInput = (data: User): UserInput => {
    console.log(data)
    return {} as UserInput
}

export const translateSearchTerms = (searchTerms: SearchOption[], t: TFunction): SearchOption[] =>
    searchTerms.map((searchTerm) => ({
        href:
            '/' +
            (t('url.' + searchTerm.href) === 'url.' + searchTerm.href ? searchTerm.href : t('url.' + searchTerm.href)), // This way we can set up links like Hilfe/Texte/5dbc0be64b6e462f75f116ff/1121233 in the searchTerms.js
        keys: searchTerm.keys.map((key) => t('searchTerms.' + key)),
        label: t('searchTerms.' + searchTerm.label),
    }))

export const validateEmail = (email: string): boolean => {
    const emailRegExp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const emailToCheck = email.replace(/ä/g, 'ae').replace(/ö/g, 'oe').replace(/ü/g, 'ue').replace(/ß/g, 'ss')
    return emailRegExp.test(emailToCheck)
}

export const addressIsFilled = (addr: Address): boolean => {
    for (const [key, value] of Object.entries(addr)) {
        if (key === '__typename') {
            continue
        }
        if (value !== '') {
            return true
        }
    }
    return false
}

export const getNewOrder = (s: SortOrder): SortOrder => {
    if (s === 'desc') return 'asc'
    if (s === 'asc') return 'desc'
    if (s === undefined) return 'desc'
}

export const isEmail = (email: string): boolean => {
    const emailRegExp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const emailToCheck = email.replace(/ä/g, 'ae').replace(/ö/g, 'oe').replace(/ü/g, 'ue').replace(/ß/g, 'ss')
    return emailRegExp.test(emailToCheck)
}
