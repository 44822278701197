import { Box, Button, Divider, Typography } from '@material-ui/core'
import TipsTricksList from 'components/TipsTricksList'
import ViewWrapper from 'components/ViewWrapper'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { getHeader, navigationLinks } from 'utils/utils'
import TestIDs from '../../utils/TestIDs'
import useTipsDetailsState from './useTipsDetailsState'

export interface TipsDetailsProps {
    tipID?: string
}

const TipsDetails = (props: RouteComponentProps<TipsDetailsProps>): JSX.Element => {
    const { tipID } = props.match.params
    const { t } = useTranslation()
    const history = useHistory()
    const { currentTip, tipsList, searchTerms } = useTipsDetailsState(tipID)

    return (
        <ViewWrapper
            header={getHeader(`/${t('url.dashboard')}`, history, t, searchTerms)}
            navigation={navigationLinks('logged-in', 'help', t)}
        >
            {currentTip && (
                <Box display={'flex'} flexDirection={'column'} marginBottom={7.5}>
                    <Box width={1} marginBottom={{ xs: 4, sm: 8 }}>
                        <Typography variant={'h1'} {...TestIDs.GET_TIPS_DETAILS('HEADLINE')}>
                            {currentTip.title}
                        </Typography>
                    </Box>
                    <Box dangerouslySetInnerHTML={{ __html: currentTip.content }} />
                    <Box marginY={8}>
                        <Divider />
                    </Box>
                    <Box>
                        <Box>
                            <Typography variant={'h2'}>{t('pages.tipsDetails.restOfTips')}</Typography>
                        </Box>
                        <TipsTricksList headline={''} tipsTricksList={tipsList} />
                    </Box>
                    <Box marginTop={5} display={'flex'} justifyContent={'flex-end'}>
                        <Button variant={'outlined'} onClick={() => history.push(`/${t('url.help')}`)}>
                            {t('pages.tipsDetails.backToHelpOverview')}
                        </Button>
                    </Box>
                </Box>
            )}
        </ViewWrapper>
    )
}

export default TipsDetails
