import gql from 'graphql-tag'

export const GET_CONTRACTS_TERMINATION = gql`
    query getContractsTermination {
        getContractsTermination {
            terminationReasons {
                terminationForConvenience
                terminationForCause
            }
            terminations {
                contractID
                targetEnd
                articleDescription
            }
        }
    }
`
